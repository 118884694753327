var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "kc-home flex-layout-styles" }, [
    _vm._m(0),
    _c("div", { staticClass: "search-wrapper f-col align-h-center" }, [
      _c("h1", { staticClass: "kc" }, [_vm._v("Search Common Fund Knowledge")]),
      _c(
        "div",
        { staticClass: "f-col fill-width" },
        [
          _c("research-single-search-cfde", {
            attrs: {
              "single-search-config": _vm.sectionConfigs["content"],
              phenotypes: _vm.phenotypesInUse,
              utils: _vm.utilsBox,
            },
          }),
          _vm.sectionConfigs["content"]["search examples"]
            ? _c("div", { staticClass: "search-extras f-row" }, [
                _c(
                  "div",
                  { staticClass: "f-row", staticStyle: { gap: "5px" } },
                  [
                    _vm._v(" Try "),
                    _vm._l(
                      _vm.sectionConfigs["content"]["search examples"],
                      function (example) {
                        return [
                          _c("a", { attrs: { href: example.url } }, [
                            _vm._v(_vm._s(example.value)),
                          ]),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._m(1),
    ]),
    _c(
      "div",
      {
        staticClass: "home-section-container",
        staticStyle: { "padding-top": "40px" },
      },
      [
        this.parsedData
          ? _c("div", { staticClass: "hero-wrapper f-col" }, [
              _c(
                "div",
                {
                  staticClass: "hero-q",
                  staticStyle: { top: "0px" },
                  on: { click: this.toggleCFinfo },
                },
                [_vm._v(" What is the Common Fund? ")]
              ),
              _c("div", { staticClass: "cf-intro f-col align-h-center" }, [
                _c("h2", { staticClass: "kc" }, [
                  _vm._v(
                    " " +
                      _vm._s(Object.keys(this.parsedData.map).length) +
                      " Common Fund Programs "
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    " Conducting groundbreaking research across diverse fields "
                  ),
                ]),
              ]),
              _vm._m(2),
              _c("div", { staticClass: "cfp-info f-col fill-width" }, [
                _vm._m(3),
                _c("div", { staticClass: "dcc-icons-contain" }, [
                  _c(
                    "div",
                    { staticClass: "dcc-icons f-row" },
                    [
                      _vm._l(this.parsedData.map, function (value, key, index) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "dcc-icon",
                              attrs: {
                                "data-group": "dccs",
                                "data-value": key,
                              },
                              on: {
                                mouseover: function ($event) {
                                  return _vm.hoverHandler($event)
                                },
                                mouseout: function ($event) {
                                  return _vm.outHandler($event)
                                },
                                click: function ($event) {
                                  return _vm.clickDcc($event)
                                },
                              },
                            },
                            [_c("img", { attrs: { src: value["logo"] } })]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "cfde-info f-col fill-width align-h-center" },
                [
                  _c("div", { staticClass: "arrw" }, [_vm._v("➤")]),
                  _c(
                    "div",
                    {
                      staticClass: "hero-q",
                      on: { click: this.toggleCFDEinfo },
                    },
                    [_vm._v(" What is the CDFE? ")]
                  ),
                  _vm._m(4),
                  _vm._m(5),
                  _c(
                    "div",
                    {
                      staticClass: "f-col",
                      staticStyle: { gap: "20px", width: "100%" },
                    },
                    [
                      _c("div", { staticClass: "eco-row f-col" }, [
                        _c("div", { staticClass: "eco-title" }, [
                          _vm._v("Omics"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "omics f-row fill-width spread-out",
                            staticStyle: { gap: "5px" },
                          },
                          _vm._l(
                            this.parsedData.sets["omics"],
                            function (value, key) {
                              return _c(
                                "div",
                                {
                                  staticClass: "omic",
                                  attrs: {
                                    "data-group": "omics",
                                    "data-value": value,
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.hoverHandler2($event)
                                    },
                                    mouseout: function ($event) {
                                      return _vm.outHandler($event)
                                    },
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(value))])]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "eco-row f-col" }, [
                        _c("div", { staticClass: "eco-title" }, [
                          _vm._v("Entities"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "entities f-row fill-width spread-out",
                            staticStyle: { gap: "5px" },
                          },
                          _vm._l(
                            this.parsedData.sets["entities"],
                            function (value, key) {
                              return _c(
                                "div",
                                {
                                  staticClass: "entity",
                                  attrs: {
                                    "data-group": "entities",
                                    "data-value": value,
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.hoverHandler2($event)
                                    },
                                    mouseout: function ($event) {
                                      return _vm.outHandler($event)
                                    },
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(value))])]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]
    ),
    _c("div", { staticClass: "home-section-container" }, [
      _c("h3", { staticClass: "section-title" }, [_vm._v("Browse")]),
      _c("div", { staticClass: "home-section-wrap" }, [
        _c("h3", { staticClass: "kc" }, [_vm._v("Examples of CFDE Knowledge")]),
        _c("div", [
          _vm._v(
            " Secondary analyses created by The Knowledge Center using Common Fund data "
          ),
        ]),
        _c(
          "div",
          { staticClass: "home-section" },
          [
            _vm._l(this.examplesData, function (example) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "example-item f-col",
                    staticStyle: { gap: "20px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "f-row", staticStyle: { gap: "20px" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "f-col",
                            staticStyle: { width: "40%", gap: "20px" },
                          },
                          [
                            _c("div", [
                              _c("div", { staticClass: "example-item-type" }, [
                                _vm._v(" Program "),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "f-row align-v-center",
                                  staticStyle: { gap: "10px" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "example-item-logo" },
                                    [
                                      _c("img", {
                                        attrs: { src: example.logo },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "example-item-name" },
                                    [_vm._v(" " + _vm._s(example.name) + " ")]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", [
                              _c("div", { staticClass: "example-item-type" }, [
                                _vm._v(" Analysis "),
                              ]),
                              _c(
                                "div",
                                { staticClass: "example-item-analysis" },
                                [_vm._v(" " + _vm._s(example.analysis) + " ")]
                              ),
                            ]),
                            _c("div", { staticClass: "f-row" }, [
                              _c(
                                "div",
                                { staticClass: "example-item-analysis" },
                                [
                                  _vm._v(" See on "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: `/r/kc_entity_${example.type.toLowerCase()}?entity=${example.type.toLowerCase()}&${example.type.toLowerCase()}=${
                                          example.entity
                                        }`,
                                      },
                                    },
                                    [_vm._v(_vm._s(example.type))]
                                  ),
                                  _vm._v(" page "),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "f-col",
                            staticStyle: { width: "calc(60% - 20px)" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "example-item-image f-col align-v-center",
                              },
                              [_c("img", { attrs: { src: example.example } })]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            }),
            _c(
              "div",
              {
                staticClass: "spotlight-prev",
                attrs: { "data-dir": "prev" },
                on: { click: this.changeExample },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      viewBox: "0 0 24 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                      stroke: "#7c7c7c",
                      "stroke-width": "1.2",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M5.707 9.71a1 1 0 0 0 0 1.415l4.892 4.887a2 2 0 0 0 2.828 0l4.89-4.89a1 1 0 1 0-1.414-1.415l-4.185 4.186a1 1 0 0 1-1.415 0L7.121 9.71a1 1 0 0 0-1.414 0Z",
                        fill: "#7c7c7c",
                      },
                    }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "spotlight-next",
                attrs: { "data-dir": "next" },
                on: { click: this.changeExample },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      viewBox: "0 0 24 24",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                      stroke: "#7c7c7c",
                      "stroke-width": "1.2",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M5.707 9.71a1 1 0 0 0 0 1.415l4.892 4.887a2 2 0 0 0 2.828 0l4.89-4.89a1 1 0 1 0-1.414-1.415l-4.185 4.186a1 1 0 0 1-1.415 0L7.121 9.71a1 1 0 0 0-1.414 0Z",
                        fill: "#7c7c7c",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ],
          2
        ),
      ]),
    ]),
    _vm._m(6),
    _c(
      "div",
      { staticClass: "home-section-container", staticStyle: { gap: "40px" } },
      [
        _c("h3", { staticClass: "section-title" }, [_vm._v("More")]),
        _c(
          "div",
          { staticClass: "f-row grow-children", staticStyle: { gap: "60px" } },
          [
            _c("div", { staticClass: "f-col", staticStyle: { gap: "40px" } }, [
              this.parsedData
                ? _c("div", { staticClass: "home-section-wrap" }, [
                    _c("h3", { staticClass: "kc" }, [
                      _vm._v("Common Fund Program Spotlight"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "home-section" },
                      [
                        _vm._l(this.parsedData.map, function (value, key) {
                          return [
                            _c("div", { staticClass: "spotlight-item" }, [
                              _c(
                                "div",
                                { staticClass: "spotlight-logo f-row" },
                                [_c("img", { attrs: { src: value.logo } })]
                              ),
                              _c("div", { staticClass: "f-col fill-width" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spotlight-text f-col fill-height",
                                  },
                                  [
                                    _c("h3", [_vm._v(_vm._s(value.name))]),
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(value.spotlight),
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticStyle: { "align-self": "flex-end" },
                                    attrs: {
                                      href: `/r/kc_programs?DCC=${key}`,
                                    },
                                  },
                                  [_vm._v("Learn More")]
                                ),
                              ]),
                            ]),
                          ]
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "spotlight-prev",
                            attrs: { "data-dir": "prev" },
                            on: { click: this.changeSpotlight },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  stroke: "#7c7c7c",
                                  "stroke-width": "1.2",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M5.707 9.71a1 1 0 0 0 0 1.415l4.892 4.887a2 2 0 0 0 2.828 0l4.89-4.89a1 1 0 1 0-1.414-1.415l-4.185 4.186a1 1 0 0 1-1.415 0L7.121 9.71a1 1 0 0 0-1.414 0Z",
                                    fill: "#7c7c7c",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "spotlight-next",
                            attrs: { "data-dir": "next" },
                            on: { click: this.changeSpotlight },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  stroke: "#7c7c7c",
                                  "stroke-width": "1.2",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M5.707 9.71a1 1 0 0 0 0 1.415l4.892 4.887a2 2 0 0 0 2.828 0l4.89-4.89a1 1 0 1 0-1.414-1.415l-4.185 4.186a1 1 0 0 1-1.415 0L7.121 9.71a1 1 0 0 0-1.414 0Z",
                                    fill: "#7c7c7c",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              this.parsedData
                ? _c("div", { staticClass: "home-section-wrap" }, [
                    _c("h3", { staticClass: "kc" }, [_vm._v("CFDE Workbench")]),
                    _vm._m(7),
                  ])
                : _vm._e(),
            ]),
            this.newsFeed
              ? _c("div", { staticClass: "home-section-wrap" }, [
                  _c("h3", { staticClass: "kc" }, [
                    _vm._v("Knowledge Center News"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "home-section f-col",
                      staticStyle: { "padding-top": "30px" },
                    },
                    [
                      _vm._l(this.newsFeed, function (item) {
                        return _c("div", { staticClass: "news-item f-row" }, [
                          _c("div", {
                            staticClass: "thumbnail",
                            domProps: {
                              innerHTML: _vm._s(item.field_thumbnail_image),
                            },
                          }),
                          _c("div", { staticClass: "f-col" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: `/r/kc_news_item?id=${item.nid}`,
                                },
                              },
                              [_c("h3", {}, [_vm._v(_vm._s(item.title))])]
                            ),
                            _c("div", {
                              domProps: { innerHTML: _vm._s(item.body) },
                            }),
                          ]),
                        ])
                      }),
                      _c(
                        "a",
                        {
                          staticStyle: { "align-self": "flex-end" },
                          attrs: { href: `/r/kc_news` },
                        },
                        [_vm._v("See All News")]
                      ),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]
    ),
    _c("div", {
      staticClass: "f-tooltip top",
      staticStyle: { top: "0", left: "50px", width: "200px", height: "100px" },
    }),
    _c("div", {
      staticClass: "f-tooltip right",
      staticStyle: { top: "0", left: "300px", width: "200px", height: "100px" },
    }),
    _c("div", {
      staticClass: "f-tooltip bottom",
      staticStyle: { top: "0", left: "550px", width: "200px", height: "100px" },
    }),
    _c("div", {
      staticClass: "f-tooltip left",
      staticStyle: { top: "0", left: "800px", width: "200px", height: "100px" },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "logo f-row" }, [
      _c("img", {
        attrs: {
          src: "https://hugeampkpncms.org/sites/default/files/users/user32/cfde_kc_logo_c.svg",
        },
      }),
      _c("span", { staticStyle: { "font-size": "16px" } }, [
        _c("strong", [
          _c("span", { staticStyle: { color: "#f26822" } }, [
            _vm._v("The Knowledge Center "),
          ]),
          _vm._v(
            " (KC) integrates data and knowledge generated by ground-breaking research programs in the NIH Common Fund Data Ecosystem."
          ),
        ]),
        _vm._v(
          " Use the KC to see curated and precomputed analyses of data within and across Common Fund projects. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "map-info" }, [
      _c("div", { staticClass: "f-col" }, [
        _c("div", { staticClass: "map-info-title" }),
        _c("div", { staticClass: "map-info-subtitle" }),
        _c("div", { staticClass: "map-info-body" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "cf-info", staticStyle: { display: "none" } },
      [
        _c("h2", { staticClass: "kc" }, [_vm._v("The Common Fund")]),
        _c("div", [
          _c("b", [_vm._v("The Common Fund (CF)")]),
          _vm._v(
            " is a program within the National Institutes of Health (NIH) designed to support innovative, high-impact research that transcends the mission of any single NIH institute or center. "
          ),
          _c(
            "a",
            {
              attrs: { href: "https://commonfund.nih.gov/", target: "_blank" },
            },
            [_vm._v("Learn more.")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "dcc-info", staticStyle: { display: "none" } },
      [
        _c("h3", { staticClass: "kc" }, [_vm._v(" Common Fund Programs ")]),
        _c("div", [
          _c("b", [_vm._v("CF Programs")]),
          _vm._v(
            " are large-scale, trans-NIH research initiatives designed to address significant biomedical challenges that no single NIH institute or center can tackle alone. "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "cfde-title f-col align-h-center" }, [
      _c("h2", { staticClass: "kc" }, [_vm._v("1 Data Ecosystem")]),
      _c("div", [
        _vm._v(
          " Allowing researchers to easily find, access, and integrate Common Fund datasets "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "what-cfde-info", staticStyle: { display: "none" } },
      [
        _c("h3", { staticClass: "kc" }, [_vm._v("Common Fund Data Ecosystem")]),
        _c("div", [
          _c("b", [_vm._v("The Common Fund Data Ecosystem (CFDE)")]),
          _vm._v(
            " is an initiative under the NIH Common Fund that aims to enhance the accessibility, interoperability, and usability of data generated by Common Fund programs."
          ),
          _c("br"),
          _c(
            "a",
            { attrs: { href: "https://info.cfde.cloud", target: "_blank" } },
            [_vm._v("Learn more about the data within the CFDE.")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "home-section-container" }, [
      _c("h3", { staticClass: "section-title" }, [_vm._v("Analyze")]),
      _c("div", { staticClass: "home-section-wrap" }, [
        _c("h3", { staticClass: "kc" }, [_vm._v("Knowledge Center Workflows")]),
        _c(
          "div",
          { staticClass: "f-row grow-children", staticStyle: { gap: "20px" } },
          [
            _c("div", { staticClass: "home-section f-col" }, [
              _c("div", { staticClass: "analysis-figure" }, [
                _c("img", {
                  attrs: {
                    src: "https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/m2h.png",
                  },
                }),
              ]),
              _c("h2", [
                _vm._v("Mouse Phenotypes to Human Phenotypes Analysis"),
              ]),
              _c("div", { staticStyle: { flex: "1" } }, [
                _vm._v(
                  " Discover mouse phenotypes genetically linked to human traits – using statistical enrichment, not just similarity. "
                ),
              ]),
              _c("div", { staticClass: "f-row spread-out" }, [
                _c(
                  "a",
                  {
                    staticStyle: { "align-self": "flex-start" },
                    attrs: { href: "/r/kc_phenotype_x_mouse2hs" },
                  },
                  [_vm._v("Explore Mouse")]
                ),
                _c(
                  "a",
                  {
                    staticStyle: { "align-self": "flex-end" },
                    attrs: { href: "/r/kc_phenotype_x_hs2mouse" },
                  },
                  [_vm._v("Explore Human")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "home-section f-col" }, [
              _c("div", { staticClass: "analysis-figure" }, [
                _c("img", {
                  attrs: {
                    src: "https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/gene_set.png",
                  },
                }),
              ]),
              _c("h2", [_vm._v("Gene Set Analysis")]),
              _c("div", { staticStyle: { flex: "1" } }, [
                _vm._v(
                  " Uncover gene set patterns across integrated Common Fund programs—designed for advanced cross-dataset exploration. "
                ),
              ]),
              _c(
                "a",
                {
                  staticStyle: { "align-self": "flex-end" },
                  attrs: {
                    href: "/r/kc_gene_set_browser_source?source=all&model=cfde",
                  },
                },
                [_vm._v("Start here")]
              ),
            ]),
            _c("div", { staticClass: "home-section f-col" }, [
              _c("div", { staticClass: "analysis-figure" }, [
                _c("img", {
                  attrs: {
                    src: "https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/dge.png",
                  },
                }),
              ]),
              _c("h2", [_vm._v("Differential Expression Analysis")]),
              _c("div", { staticStyle: { flex: "1" } }, [
                _vm._v(
                  " Compare differentially expressed genes across tissues and diseases, driving insights into gene regulation and disease mechanisms. "
                ),
              ]),
              _c(
                "a",
                {
                  staticStyle: { "align-self": "flex-end" },
                  attrs: { href: "/r/kc_dge_top_20?q=1&source=kc_diffexp" },
                },
                [_vm._v("Start here")]
              ),
            ]),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "home-section" }, [
      _c("div", { staticClass: "f-row", staticStyle: { gap: "20px" } }, [
        _c("div", { staticClass: "drc-logo" }, [
          _c("img", {
            attrs: {
              src: "https://hugeampkpncms.org/sites/default/files/users/user32/kc_icons/DRC_logo.png",
            },
          }),
        ]),
        _c("div", { staticClass: "f-col" }, [
          _c("div", [
            _vm._v(
              " Visit our sister resource to query, access, and compute Common Fund datasets. "
            ),
          ]),
          _c("ul", [
            _c("li", [
              _vm._v(
                " Search across all Common Fund metadata and processed data. "
              ),
            ]),
            _c("li", [
              _vm._v(
                " Cloud tools to interrogate data sets from various Common Fund programs. "
              ),
            ]),
            _c("li", [
              _vm._v(
                " Training and outreach to highlight Common Fund data and how to use it effectively. "
              ),
            ]),
          ]),
          _c("div", { staticClass: "drc-link" }, [
            _c(
              "a",
              { attrs: { href: "https://data.cfde.cloud/", target: "_blank" } },
              [_vm._v("Visit CFDE Workbench")]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }