var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticStyle: { height: "100%", display: "flex" } }, [
    _vm.layout === "list"
      ? _c(
          "div",
          {
            staticClass: "list-container",
            class: [_vm.listDirection, _vm.listAlignment],
          },
          _vm._l(_vm.listData, function (label) {
            return _c(
              "div",
              {
                key: label,
                staticClass: "list-option",
                attrs: { "data-label": label },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip:hover.left.window",
                        value: "isolate label",
                        expression: "'isolate label'",
                        arg: "hover",
                        modifiers: { left: true, window: true },
                      },
                    ],
                    staticClass: "colorize-option",
                    class: [
                      _vm.labelIsolated(_vm.coloredOption, label),
                      { selected: label === _vm.selectedOption },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.colorLabel($event, label)
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "1em",
                          viewBox: "0 -0.5 17 17",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M3 10.333C3 13.463 5.427 16 8.418 16 11.41 16 14 13.463 14 10.333 14 7.204 8.418 0 8.418 0S3 7.204 3 10.333Z",
                            fill: _vm.colors
                              ? _vm.colors[_vm.selectedOption][label]
                              : label === _vm.selectedOption
                              ? "#434343"
                              : "#ccc",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "option-label",
                    attrs: { title: label },
                    on: {
                      mouseover: function ($event) {
                        return _vm.emitHover(label)
                      },
                      mouseout: function ($event) {
                        return _vm.emitHover("")
                      },
                    },
                  },
                  [_vm._v(_vm._s(label))]
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm.layout === "dropdown"
      ? _c("div", { staticClass: "dropdown-container" }, [
          _c("div", { staticClass: "dropdown-group" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedOption,
                    expression: "selectedOption",
                  },
                ],
                staticStyle: { width: "100%" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedOption = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.selectOption($event.target.value)
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "" } }, [_vm._v("--Select--")]),
                _vm._l(_vm.data, function (value, key) {
                  return _c("option", { domProps: { value: key } }, [
                    _vm._v(" " + _vm._s(key) + " "),
                  ])
                }),
              ],
              2
            ),
          ]),
        ])
      : _vm._e(),
    _vm.layout === "dropdown-list"
      ? _c("div", { staticClass: "dropdown-container" }, [
          _c(
            "div",
            { staticClass: "dropdown-group" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip:hover.left",
                      value: "color all labels",
                      expression: "'color all labels'",
                      arg: "hover",
                      modifiers: { left: true },
                    },
                  ],
                  staticClass: "colorize-option on",
                  on: {
                    click: function ($event) {
                      return _vm.colorOption(_vm.selectedOption)
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "1em",
                        viewBox: "0 -0.5 17 17",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M3 10.333C3 13.463 5.427 16 8.418 16 11.41 16 14 13.463 14 10.333 14 7.204 8.418 0 8.418 0S3 7.204 3 10.333Z",
                          fill: "#434343",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm.showSelect
                ? [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedOption,
                            expression: "selectedOption",
                          },
                        ],
                        staticStyle: { width: "100%" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedOption = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              return _vm.selectOption($event.target.value)
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("--Select--"),
                        ]),
                        _vm._l(_vm.data, function (value, key) {
                          return _c("option", { domProps: { value: key } }, [
                            _vm._v(" " + _vm._s(key) + " "),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "font-weight": "bold",
                        },
                      },
                      [_vm._v(_vm._s(_vm.selectedOption))]
                    ),
                  ],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "dropdown-content" },
            _vm._l(_vm.data[_vm.selectedOption], function (label) {
              return _c(
                "div",
                {
                  key: label,
                  staticClass: "dropdown-option",
                  on: {
                    click: function ($event) {
                      return _vm.colorLabel($event, label)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip:hover.left.window",
                          value: "isolate label",
                          expression: "'isolate label'",
                          arg: "hover",
                          modifiers: { left: true, window: true },
                        },
                      ],
                      staticClass: "colorize-option",
                      class: _vm.labelIsolated(_vm.coloredOption, label),
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "1em",
                            viewBox: "0 -0.5 17 17",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M3 10.333C3 13.463 5.427 16 8.418 16 11.41 16 14 13.463 14 10.333 14 7.204 8.418 0 8.418 0S3 7.204 3 10.333Z",
                              fill: _vm.colors
                                ? _vm.colors[_vm.selectedOption][label]
                                : "#434343",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "option-label",
                      attrs: { title: label },
                      on: {
                        mouseover: function ($event) {
                          return _vm.emitHover(label)
                        },
                        mouseout: function ($event) {
                          return _vm.emitHover("")
                        },
                      },
                    },
                    [_vm._v(_vm._s(label))]
                  ),
                ]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm.layout === "accordion"
      ? _c(
          "div",
          { staticClass: "accordion-container" },
          [
            _vm._l(_vm.data, function (value, key) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "accordion-group",
                    class: [
                      key === _vm.selectedOption ? "selected" : "",
                      key === _vm.coloredOption ? "colored" : "",
                    ],
                  },
                  [
                    _c("div", { staticClass: "accordion-control" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip:hover.left",
                              value: "color all labels",
                              expression: "'color all labels'",
                              arg: "hover",
                              modifiers: { left: true },
                            },
                          ],
                          staticClass: "colorize-option",
                          on: {
                            click: function ($event) {
                              return _vm.colorOption(key)
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "1em",
                                viewBox: "0 -0.5 17 17",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M3 10.333C3 13.463 5.427 16 8.418 16 11.41 16 14 13.463 14 10.333 14 7.204 8.418 0 8.418 0S3 7.204 3 10.333Z",
                                  fill: "#434343",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "select-option",
                          on: {
                            click: function ($event) {
                              return _vm.selectOption(key)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "option-label",
                              attrs: { title: key },
                            },
                            [_vm._v(_vm._s(key))]
                          ),
                          _c("div", { staticClass: "arrow" }, [
                            _c(
                              "svg",
                              {
                                staticStyle: { "font-size": "10px" },
                                attrs: {
                                  height: "1em",
                                  viewBox: "0 0 320 512",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  "stroke-width": "0",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z",
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "accordion-content",
                        attrs: { "data-key": key },
                      },
                      _vm._l(value, function (label) {
                        return _c(
                          "div",
                          { key: label, staticClass: "label-option" },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip:hover.left.window",
                                    value: "isolate label",
                                    expression: "'isolate label'",
                                    arg: "hover",
                                    modifiers: { left: true, window: true },
                                  },
                                ],
                                staticClass: "colorize-option",
                                class: _vm.labelIsolated(key, label),
                                attrs: { disabled: key !== _vm.coloredOption },
                                on: {
                                  click: function ($event) {
                                    return _vm.colorLabel($event, label)
                                  },
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "1em",
                                      viewBox: "0 -0.5 17 17",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M3 10.333C3 13.463 5.427 16 8.418 16 11.41 16 14 13.463 14 10.333 14 7.204 8.418 0 8.418 0S3 7.204 3 10.333Z",
                                        fill:
                                          _vm.colors &&
                                          key === _vm.coloredOption
                                            ? _vm.colors[key][label]
                                            : "#434343",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "option-label",
                                attrs: { title: label },
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.emitHover(label)
                                  },
                                  mouseout: function ($event) {
                                    return _vm.emitHover("")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(label))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }