var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.data
    ? _c(
        "div",
        {
          staticStyle: {
            height: "100%",
            display: "flex",
            width: "100%",
            "flex-direction": "column",
            gap: "10px",
            margin: "0 0 10px 0",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                gap: "20px",
                background: "#f8f8f8",
                padding: "20px",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    flex: "1",
                  },
                },
                [
                  _c("strong", { staticStyle: { "font-size": "20px" } }, [
                    _vm._v(_vm._s(_vm.data["datasetName"])),
                  ]),
                  _c("em", [_vm._v(_vm._s(_vm.data["authors"] || ""))]),
                  _c(
                    "div",
                    { staticStyle: { flex: "1", position: "relative" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "0",
                            right: "0",
                            bottom: "0",
                            left: "0",
                            "max-height": "-webkit-fill-available",
                            overflow: "scroll",
                            "text-overflow": "ellipsis",
                            margin: "10px 0 0",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.data["summary"] || "") + " ")]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "border-left": "1px solid #ccc",
                    "padding-left": "20px",
                    width: "25%",
                  },
                },
                [
                  _c("div", { staticClass: "metadata-item" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "min-width": "100px",
                        },
                      },
                      [_vm._v("Species")]
                    ),
                    _c("div", [_vm._v(_vm._s(_vm.data["species"]))]),
                  ]),
                  _c("div", { staticClass: "metadata-item" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "min-width": "100px",
                        },
                      },
                      [_vm._v("Tissue")]
                    ),
                    _c("div", [_vm._v(_vm._s(_vm.data["tissue"]))]),
                  ]),
                  _vm.data["depot"]
                    ? _c("div", { staticClass: "metadata-item" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "min-width": "100px",
                            },
                          },
                          [_vm._v("Depot")]
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.data["depot"]))]),
                      ])
                    : _vm._e(),
                  _vm.data["depot2"]
                    ? _c("div", { staticClass: "metadata-item" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "min-width": "100px",
                            },
                          },
                          [_vm._v("Sub-Depot")]
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.data["depot2"]))]),
                      ])
                    : _vm._e(),
                  _vm.data["totalCells"]
                    ? _c("div", { staticClass: "metadata-item" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "min-width": "100px",
                            },
                          },
                          [_vm._v("Cell Count")]
                        ),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.data["totalCells"]?.toLocaleString())
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.data["method"]
                    ? _c("div", { staticClass: "metadata-item" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "min-width": "100px",
                            },
                          },
                          [_vm._v("Method")]
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.data["method"]))]),
                      ])
                    : _vm._e(),
                  _vm.data["platform"]
                    ? _c("div", { staticClass: "metadata-item" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "min-width": "100px",
                            },
                          },
                          [_vm._v("Platform")]
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.data["platform"]))]),
                      ])
                    : _vm._e(),
                  _vm.data["disease__ontology_label"]
                    ? _c(
                        "div",
                        { staticClass: "metadata-item" },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                "min-width": "100px",
                              },
                            },
                            [_vm._v("Disease")]
                          ),
                          Array.isArray(_vm.data["disease__ontology_label"])
                            ? _vm._l(
                                _vm.data["disease__ontology_label"],
                                function (item) {
                                  return _c("div", [_vm._v(_vm._s(item))])
                                }
                              )
                            : [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.data["disease__ontology_label"])
                                  ),
                                ]),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.data["contact"]
                    ? _c("div", { staticClass: "metadata-item" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "min-width": "100px",
                            },
                          },
                          [_vm._v("Contact")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "overflow-wrap": "break-word",
                            },
                          },
                          [_vm._v(_vm._s(_vm.data["contact"]))]
                        ),
                      ])
                    : _vm._e(),
                  _vm.data["doi"]
                    ? _c("div", { staticClass: "metadata-item" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "min-width": "100px",
                            },
                          },
                          [_vm._v("DOI")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "overflow-wrap": "break-word",
                            },
                          },
                          [
                            _c("a", { attrs: { href: _vm.data["doi"] } }, [
                              _vm._v(_vm._s(_vm.data["doi"])),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.data["download"]
                    ? _c("div", { staticClass: "metadata-item" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "min-width": "100px",
                            },
                          },
                          [_vm._v("Download")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "overflow-wrap": "break-word",
                            },
                          },
                          [
                            _c("a", { attrs: { href: _vm.data["download"] } }, [
                              _vm._v("click here"),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }