var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "col-md-12" },
    [
      !!_vm.plotData
        ? _c("div", {
            staticClass: "plot-legend",
            domProps: { innerHTML: _vm._s(_vm.plotLegend) },
          })
        : _vm._e(),
      !!_vm.plotConfig
        ? [
            _vm.plotConfig["type"] == "manhattan bitmap plot"
              ? _c("research-m-bitmap-plot", {
                  attrs: {
                    plotData: _vm.plotData,
                    renderConfig: _vm.plotConfig,
                    dataComparisonConfig: _vm.dataComparisonConfig,
                    compareGroupColors: _vm.colors.moderate,
                    utils: _vm.utils,
                    sectionId: _vm.sectionId,
                  },
                })
              : _vm._e(),
            _vm.plotConfig["type"] == "box plot"
              ? _c("research-box-plot", {
                  attrs: {
                    phenotypesData: _vm.plotData,
                    phenotypeMap: _vm.phenotypeMap,
                    colors: _vm.colors.extraBold,
                    plotMargin: _vm.plotMargin,
                    renderConfig: _vm.plotConfig,
                    pkgData: null,
                    pkgDataSelected: null,
                    canvasId: _vm.sectionId,
                    utils: _vm.utils,
                  },
                })
              : _vm._e(),
            _vm.plotConfig["type"] == "bar plot"
              ? _c("research-bar-plot", {
                  attrs: {
                    phenotypesData: _vm.plotData,
                    phenotypeMap: _vm.phenotypeMap,
                    colors: _vm.colors.extraBold,
                    plotMargin: _vm.plotMargin,
                    renderConfig: _vm.plotConfig,
                    pkgData: null,
                    pkgDataSelected: null,
                    canvasId: _vm.sectionId,
                    utils: _vm.utils,
                  },
                })
              : _vm._e(),
            _vm.plotConfig["type"] == "cfde xing"
              ? _c("research-cfde-xing", {
                  attrs: {
                    data: _vm.plotData,
                    colors: _vm.colors,
                    plotMargin: _vm.plotMargin,
                    renderConfig: _vm.plotConfig,
                    canvasId: _vm.sectionId,
                    utils: _vm.utils,
                  },
                })
              : _vm._e(),
            _vm.plotConfig["type"] == "bar in cell plot"
              ? _c("research-bar-in-cell-plot", {
                  attrs: {
                    plotData: _vm.plotData,
                    plotMargin: _vm.plotMargin,
                    plotConfig: _vm.plotConfig,
                    canvasId: _vm.sectionId,
                    utils: _vm.utils,
                  },
                })
              : _vm._e(),
            _vm.plotConfig["type"] == "phewas plot"
              ? _c("research-phewas-plot", {
                  attrs: {
                    phenotypesData: _vm.plotData,
                    phenotypeMap: _vm.phenotypeMap,
                    colors: _vm.colors.extraBold,
                    plotMargin: _vm.plotMargin,
                    renderConfig: _vm.plotConfig,
                    pkgData: null,
                    pkgDataSelected: null,
                    canvasId: _vm.sectionId,
                    utils: _vm.utils,
                  },
                })
              : _vm._e(),
            _vm.plotConfig["type"] == "heat map"
              ? _c("research-heatmap", {
                  attrs: {
                    heatmapData: _vm.plotData,
                    renderConfig: _vm.plotConfig,
                    utils: _vm.utils,
                    sectionId: _vm.sectionId,
                  },
                })
              : _vm._e(),
            _vm.plotConfig["type"] == "volcano plot"
              ? _c("research-volcano-plot", {
                  attrs: {
                    plotData: _vm.plotData,
                    renderConfig: _vm.plotConfig,
                    utils: _vm.utils,
                    sectionId: _vm.sectionId,
                  },
                })
              : _vm._e(),
            _vm.plotConfig["type"] == "manhattan qq plot"
              ? _c("research-m-qq-plot", {
                  attrs: {
                    plotData: _vm.plotData,
                    renderConfig: _vm.plotConfig,
                    utils: _vm.utils,
                    sectionId: _vm.sectionId,
                  },
                })
              : _vm._e(),
            !!_vm.plotConfig &&
            _vm.plotConfig["type"] == "region plot" &&
            !!_vm.region
              ? _c("multi-region-plot", {
                  attrs: {
                    plotData: _vm.plotData,
                    renderConfig: _vm.plotConfig,
                    searchParameters: _vm.searchParameters,
                    dataComparisonConfig: _vm.dataComparisonConfig,
                    region: _vm.region,
                    plotMargin: _vm.plotMargin,
                    compareGroupColors: _vm.colors.moderate,
                    regionZoom: _vm.regionZoom,
                    regionViewArea: _vm.regionViewArea,
                    pkgData: null,
                    pkgDataSelected: null,
                    isSectionPage: true,
                    sectionId: _vm.sectionId,
                    utils: _vm.utils,
                    starItems: _vm.starItems,
                    colors: _vm.colors,
                  },
                  on: { "on-star": _vm.starColumn },
                })
              : _vm._e(),
            !!_vm.plotConfig &&
            _vm.plotConfig["type"] == "region track" &&
            !!_vm.region
              ? _c("research-region-track", {
                  attrs: {
                    sectionId: _vm.sectionId,
                    plotConfig: _vm.plotConfig,
                    plotData: _vm.plotData,
                    dataComparisonConfig: _vm.dataComparisonConfig,
                    regionParam: _vm.region,
                    regionZoom: _vm.regionZoom,
                    regionViewArea: _vm.regionViewArea,
                    colors: _vm.colors,
                    utils: _vm.utils,
                    plotMargin: _vm.plotMargin,
                    starItems: _vm.starItems,
                  },
                })
              : _vm._e(),
            !!_vm.plotConfig &&
            _vm.plotConfig["type"] == "region dots track" &&
            !!_vm.region
              ? _c("research-region-dots-track", {
                  attrs: {
                    sectionId: _vm.sectionId,
                    plotConfig: _vm.plotConfig,
                    plotData: _vm.plotData,
                    dataComparisonConfig: _vm.dataComparisonConfig,
                    region: _vm.region,
                    regionZoom: _vm.regionZoom,
                    regionViewArea: _vm.regionViewArea,
                    colors: _vm.colors,
                    utils: _vm.utils,
                    plotMargin: _vm.plotMargin,
                    starItems: _vm.starItems,
                  },
                })
              : _vm._e(),
            !!_vm.plotConfig &&
            (_vm.plotConfig["type"] == "region plot" ||
              _vm.plotConfig["type"] == "region track" ||
              _vm.plotConfig["type"] == "region dots track") &&
            !!_vm.plotConfig["genes track"] &&
            !!_vm.region
              ? _c("multi-genes-track", {
                  attrs: {
                    region: _vm.region,
                    genesData: null,
                    plotConfig: _vm.plotConfig,
                    plotType: _vm.plotConfig["type"],
                    plotMargin: _vm.plotMargin,
                    regionZoom: _vm.regionZoom,
                    regionViewArea: _vm.regionViewArea,
                    utils: _vm.utils,
                    sectionId: _vm.sectionId,
                    starItems: _vm.starItems,
                  },
                })
              : _vm._e(),
            !!_vm.plotConfig && _vm.plotConfig["type"] == "scatter plot"
              ? _c("research-scatter-plot", {
                  attrs: {
                    plotData: _vm.plotData,
                    renderConfig: _vm.plotConfig,
                    searchParameters: _vm.searchParameters,
                    dataComparisonConfig: _vm.dataComparisonConfig,
                    plotMargin: _vm.plotMargin,
                    compareGroupColors: _vm.colors.moderate,
                    isSectionPage: true,
                    sectionId: _vm.sectionId,
                    starItems: _vm.starItems,
                    utils: _vm.utils,
                  },
                })
              : _vm._e(),
            _vm.plotConfig && _vm.plotConfig["type"] == "cell browser"
              ? _c("research-single-cell-browser", {
                  attrs: {
                    sectionId: _vm.sectionId,
                    renderConfig: _vm.plotConfig,
                    utils: _vm.utils,
                    data: _vm.plotData,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }