var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "phenotype-xing-legend" }, [
      _c("strong", [_vm._v(_vm._s(_vm.renderConfig["x axis field"]) + ": ")]),
      _vm._v(
        " Max: " +
          _vm._s(_vm.utils.Formatters.pValueFormatter(_vm.dataMax)) +
          ", "
      ),
      _c("span", { staticStyle: { color: "#ffaa00" } }, [
        _vm._v(
          "Mean: " + _vm._s(_vm.utils.Formatters.pValueFormatter(_vm.dataMean))
        ),
      ]),
      _vm._v(
        ", Min: " +
          _vm._s(_vm.utils.Formatters.pValueFormatter(_vm.dataMin)) +
          " | "
      ),
      _c("strong", [
        _vm._v(_vm._s(_vm.renderConfig["suppliment field"]) + " "),
        _c(
          "span",
          {
            staticClass: "value-dot",
            staticStyle: {
              "background-color": "#000000",
              width: "10px",
              height: "10px",
              top: "5px",
              position: "relative",
              display: "inline-block",
            },
          },
          [_vm._v(" ")]
        ),
        _vm._v(" : "),
      ]),
      _vm._v(
        " Max: " +
          _vm._s(_vm.utils.Formatters.pValueFormatter(_vm.suppMax)) +
          ", Mean: " +
          _vm._s(_vm.utils.Formatters.pValueFormatter(_vm.suppMean)) +
          ", Min: " +
          _vm._s(_vm.utils.Formatters.pValueFormatter(_vm.suppMin)) +
          " "
      ),
    ]),
    _c("table", { staticClass: "cfde-xing-table" }, [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v(_vm._s(_vm.renderConfig["render by"]))]),
          _c("th", [_vm._v(_vm._s(_vm.renderConfig["x axis field"]))]),
          _c("th", [_vm._v(_vm._s(_vm.renderConfig["suppliment field"]))]),
          _c("th", [_vm._v("Background")]),
          _c("th", [
            _vm._v(
              "Log10(" +
                _vm._s(_vm.renderConfig["x axis field"]) +
                ") : " +
                _vm._s(_vm.renderConfig["suppliment field"])
            ),
          ]),
        ]),
      ]),
      _c(
        "tbody",
        [
          _vm._l(_vm.renderData, function (item, iIndex) {
            return _c("tr", { class: iIndex % 2 == 0 ? "shady-tr" : "" }, [
              _c(
                "td",
                { staticClass: "phenotype-td" },
                [
                  !!_vm.renderConfig["link to"]
                    ? [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                _vm.renderConfig["link to"] +
                                item[_vm.renderConfig["link field"]],
                            },
                          },
                          [_vm._v(_vm._s(item[_vm.renderConfig["render by"]]))]
                        ),
                      ]
                    : [
                        _vm._v(
                          " " +
                            _vm._s(item[_vm.renderConfig["render by"]]) +
                            " "
                        ),
                      ],
                ],
                2
              ),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.Formatters.pValueFormatter(
                        item[_vm.renderConfig["x axis field"]]
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.utils.Formatters.pValueFormatter(
                        item[_vm.renderConfig["suppliment field"]]
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", [_vm._v(_vm._s(item["Background"]))]),
              _c("td", { staticClass: "value-td" }, [
                _c("span", {
                  staticClass: "mean-pos",
                  style: "left:" + _vm.getMeanXpos(_vm.dataMean),
                }),
                _c(
                  "span",
                  {
                    staticClass: "value-dot",
                    style:
                      "background-color:" +
                      _vm.getBGColor(item[_vm.renderConfig["color by"]]) +
                      ";left:" +
                      _vm.getXpos(
                        item[_vm.renderConfig["x axis field"]],
                        item[_vm.renderConfig["suppliment field"]]
                      ) +
                      "width:" +
                      _vm.getDotScale(
                        item[_vm.renderConfig["suppliment field"]]
                      ) +
                      "px;height:" +
                      _vm.getDotScale(
                        item[_vm.renderConfig["suppliment field"]]
                      ) +
                      "px;",
                  },
                  [_vm._v("   ")]
                ),
              ]),
            ])
          }),
          _c("tr", [
            _c("td"),
            _c("td"),
            _c("td"),
            _c("td"),
            _c("td", [
              _c(
                "span",
                { staticStyle: { float: "left", "margin-left": "-10px" } },
                [
                  _vm._v(
                    _vm._s(_vm.utils.Formatters.pValueFormatter(_vm.dataMin))
                  ),
                ]
              ),
              _c(
                "span",
                { staticStyle: { float: "right", "margin-right": "-10px" } },
                [
                  _vm._v(
                    _vm._s(_vm.utils.Formatters.pValueFormatter(_vm.dataMax))
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }