var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "sub-section-header" }, [
      !!_vm.subSectionConfig["label"]
        ? _c("strong", [_vm._v(_vm._s(_vm.subSectionConfig["label"]))])
        : _vm._e(),
    ]),
    !!_vm.subSectionConfig["visualizers"] &&
    (_vm.subSectionConfig["visualizers"]["wrapper type"] == "tabs" ||
      _vm.subSectionConfig["visualizers"]["wrapper type"] == "divs")
      ? _c("div", { staticClass: "sub-plot-wrapper" }, [
          _c(
            "div",
            {
              staticClass: "sub-tab-ui-wrapper",
              attrs: { id: "tabUiGroup" + _vm.rowId },
            },
            _vm._l(
              _vm.subSectionConfig["visualizers"]["visualizers"],
              function (tab, tabIndex) {
                return _c(
                  "div",
                  {
                    staticClass: "tab-ui-tab",
                    class: tabIndex == 0 ? "active" : "",
                    attrs: { id: "tabUi" + _vm.rowId + tabIndex },
                    on: {
                      click: function ($event) {
                        return _vm.utils.uiUtils.setTabActive(
                          "tabUi" + _vm.rowId + tabIndex,
                          "tabUiGroup" + _vm.rowId,
                          "tabContent" + _vm.rowId + tabIndex,
                          "tabContentGroup" + _vm.rowId,
                          true
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          tab.label /*utils.Formatters.replaceWithParams(tab.label, pageParams)*/
                        ) +
                        " "
                    ),
                  ]
                )
              }
            ),
            0
          ),
          _c(
            "div",
            {
              attrs: {
                id:
                  _vm.subSectionConfig["visualizers"]["wrapper type"] == "tabs"
                    ? "tabContentGroup" + _vm.rowId
                    : "",
              },
            },
            _vm._l(
              _vm.subSectionConfig["visualizers"]["visualizers"],
              function (plotConfig, plotIndex) {
                return _c(
                  "div",
                  {
                    staticClass: "plot-tab-content-wrapper",
                    class:
                      _vm.subSectionConfig["visualizers"]["wrapper type"] ==
                      "tabs"
                        ? plotIndex == 0
                          ? ""
                          : "hidden-content"
                        : "",
                    attrs: {
                      id:
                        _vm.subSectionConfig["visualizers"]["wrapper type"] ==
                        "tabs"
                          ? "tabContent" + _vm.rowId + plotIndex
                          : "",
                    },
                  },
                  [
                    _vm.subSectionConfig["visualizers"]["wrapper type"] !=
                    "tabs"
                      ? _c("h6", {
                          domProps: { innerHTML: _vm._s(plotConfig.label) },
                        })
                      : _vm._e(),
                    _c("research-section-visualizers", {
                      attrs: {
                        plotConfig: plotConfig,
                        plotData: _vm.currentData,
                        phenotypeMap: _vm.phenotypeMap,
                        colors: _vm.colors,
                        plotMargin: _vm.plotMargin,
                        sectionId: (_vm.rowId + plotIndex).replaceAll(",", ""),
                        utils: _vm.utils,
                        searchParameters: _vm.rowId,
                      },
                    }),
                  ],
                  1
                )
              }
            ),
            0
          ),
        ])
      : _vm._e(),
    !!_vm.subSectionConfig["visualizer"]
      ? _c(
          "div",
          { staticClass: "sub-plot-wrapper" },
          [
            _c("research-section-visualizers", {
              attrs: {
                plotConfig: _vm.subSectionConfig["visualizer"],
                plotData: _vm.currentData,
                phenotypeMap: _vm.phenotypeMap,
                colors: _vm.colors,
                plotMargin: _vm.plotMargin,
                sectionId: _vm.rowId.replaceAll(",", ""),
                utils: _vm.utils,
                searchParameters: _vm.rowId,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "table",
      {
        staticClass:
          "table table-sm table-striped research-data-table subsection-table",
      },
      [
        _c("thead", [
          _c(
            "tr",
            [
              !!_vm.tableFormat["star column"]
                ? _c(
                    "th",
                    { staticClass: "star-items-control" },
                    [
                      _c("b-icon", {
                        staticStyle: { color: "#ffcc00", cursor: "pointer" },
                        attrs: { icon: !!_vm.stared ? "star-fill" : "star" },
                      }),
                      _c("span", { staticClass: "star-items-options" }, [
                        _c("ul", [
                          _c("li", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showHideStared()
                                  },
                                },
                              },
                              [_vm._v("Show stard only")]
                            ),
                          ]),
                          _c("li", [
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function ($event) {
                                    return _vm.starAll()
                                  },
                                },
                              },
                              [_vm._v("Star / unstar all")]
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.getTopRows(), function (head) {
                return _c("th", [_c("span", [_vm._v(_vm._s(head))])])
              }),
            ],
            2
          ),
        ]),
        _c(
          "tbody",
          [
            _vm._l(_vm.subPageData, function (row, rowIndex) {
              return _c(
                "tr",
                [
                  !!_vm.tableFormat["star column"]
                    ? _c("td", [
                        _vm.checkStared("1", row) == false
                          ? _c(
                              "span",
                              [
                                _c("b-icon", {
                                  staticStyle: {
                                    color: "#aaaaaa",
                                    cursor: "pointer",
                                  },
                                  attrs: { icon: "star" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addStar(row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.checkStared("2", row) == true
                          ? _c(
                              "span",
                              [
                                _c("b-icon", {
                                  staticStyle: {
                                    color: "#ffcc00",
                                    cursor: "pointer",
                                  },
                                  attrs: { icon: "star-fill" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeStar(row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.getTopRows(), function (head) {
                    return _c(
                      "td",
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.formatValue(row[head], head, row)
                            ),
                          },
                        }),
                        _vm.tableFormat["column formatting"] &&
                        _vm.tableFormat["column formatting"][head] &&
                        _vm.tableFormat["column formatting"][
                          head
                        ].type.includes("copy to clipboard")
                          ? _c(
                              "b-btn",
                              {
                                staticClass: "copy-to-clipboard",
                                on: {
                                  click: function ($event) {
                                    return _vm.utils.uiUtils.copy2Clipboard(
                                      row[head]
                                    )
                                  },
                                },
                              },
                              [_vm._v("Copy")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            }),
            _c("tr", [
              _c(
                "td",
                { attrs: { colspan: _vm.getTopRows().length } },
                [
                  _c(
                    "b-container",
                    {
                      staticClass:
                        "egl-table-page-ui-wrapper subsection-page-ui-left",
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s("Total rows: " + _vm.currentData.length)),
                      ]),
                    ]
                  ),
                  _c(
                    "b-container",
                    {
                      staticClass:
                        "egl-table-page-ui-wrapper subsection-page-ui-center",
                    },
                    [
                      _c("b-pagination", {
                        staticClass: "pagination-sm justify-content-center",
                        attrs: {
                          "total-rows": _vm.currentData.length,
                          "per-page": _vm.numberOfRows,
                          phenotypeMap: _vm.phenotypeMap,
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-container",
                    {
                      staticClass:
                        "egl-table-page-ui-wrapper subsection-page-ui-right",
                    },
                    [
                      _c("div", [
                        _c("strong", [_vm._v("Save data in section: ")]),
                        _c(
                          "div",
                          {
                            staticClass: "convert-2-csv btn-sm",
                            on: {
                              click: function ($event) {
                                return _vm.convertJson2Csv(
                                  _vm.currentData,
                                  _vm.rowId + "_subsection"
                                )
                              },
                            },
                          },
                          [_vm._v(" CSV ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "convert-2-csv btn-sm",
                            on: {
                              click: function ($event) {
                                return _vm.saveJson(
                                  _vm.currentData,
                                  _vm.rowId + "_subsection"
                                )
                              },
                            },
                          },
                          [_vm._v(" JSON ")]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }