var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mbm-plot-content row" }, [
    _c("div", { staticClass: "col-md-12 bar-plot-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "col-md-12",
          staticStyle: { display: "inline-block" },
          attrs: { id: _vm.canvasId + "barInCellPlotWrapper" },
        },
        [
          _c("canvas", {
            attrs: {
              hidden: !_vm.showCanvas,
              id: _vm.canvasId + "barInCellPlot",
              width: "",
              height: "",
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }