var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "region-track-wrapper",
      attrs: { id: "region_track_wrapper" + _vm.sectionId },
    },
    [
      _c(
        "div",
        {
          staticClass: "block-data hidden",
          attrs: { id: "block_data_" + _vm.sectionId },
        },
        [
          _c(
            "div",
            {
              staticClass: "fixed-info-box-close",
              on: {
                click: function ($event) {
                  _vm.infoBoxFrozen = false
                  _vm.hidePanel("block_data_" + _vm.sectionId)
                },
              },
            },
            [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
            1
          ),
          _c(
            "div",
            {
              staticClass: "block-data-content",
              attrs: { id: "block_data_content_" + _vm.sectionId },
            },
            [
              _vm._l(_vm.infoBoxContent.data, function (item, itemIndex) {
                return [
                  _vm.infoBoxContent.action == "hover" && itemIndex < 5
                    ? _c(
                        "span",
                        [
                          _c("strong", [_vm._v(_vm._s(item.title))]),
                          _vm._l(item, function (iValue, iKey) {
                            return [
                              iKey != "title"
                                ? _c("span", [
                                    _c("br"),
                                    _vm._v(_vm._s(iKey + ": " + iValue)),
                                  ])
                                : _vm._e(),
                            ]
                          }),
                          !!_vm.plotConfig["set region parameter by"] &&
                          !!item[
                            _vm.plotConfig["set region parameter by"]["field"]
                          ]
                            ? [
                                _c("br"),
                                _c(
                                  "span",
                                  { staticStyle: { color: "#5fa331" } },
                                  [_vm._v("Click to set region.")]
                                ),
                              ]
                            : _vm._e(),
                          _c("br"),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.infoBoxContent.action == "click"
                    ? _c(
                        "span",
                        [
                          _c("strong", [_vm._v(_vm._s(item.title))]),
                          _vm._l(item, function (iValue, iKey) {
                            return [
                              iKey != "title"
                                ? _c("span", [
                                    _c("br"),
                                    _vm._v(_vm._s(iKey + ": " + iValue)),
                                  ])
                                : _vm._e(),
                            ]
                          }),
                          !!_vm.plotConfig["set region parameter by"] &&
                          !!item[
                            _vm.plotConfig["set region parameter by"]["field"]
                          ]
                            ? [
                                _c("br"),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary btn-sm",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setRegion(
                                          item[
                                            _vm.plotConfig[
                                              "set region parameter by"
                                            ]["field"]
                                          ],
                                          _vm.plotConfig[
                                            "set region parameter by"
                                          ]["parameter"],
                                          _vm.plotConfig[
                                            "set region parameter by"
                                          ]["max region"]
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Set region ")]
                                ),
                                _c("br"),
                                _vm._v(
                                  "Max length: " +
                                    _vm._s(
                                      _vm.plotConfig["set region parameter by"][
                                        "max region"
                                      ]
                                    ) +
                                    " "
                                ),
                              ]
                            : _vm._e(),
                          _c("br"),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.infoBoxContent.data.length > 1
                    ? [_c("br"), _c("br")]
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "col-md-11" },
        _vm._l(_vm.colorGroups, function (cKey, index) {
          return _c(
            "span",
            {
              key: cKey,
              staticClass: "color-groups",
              on: {
                mouseover: function ($event) {
                  return _vm.renderPlot(cKey)
                },
                mouseleave: function ($event) {
                  return _vm.renderPlot()
                },
              },
            },
            [
              _c("span", {
                staticClass: "box",
                style: "background-color:" + _vm.colors.bold[index % 16],
              }),
              _c("span", {
                staticClass: "label",
                domProps: { innerHTML: _vm._s(cKey) },
              }),
            ]
          )
        }),
        0
      ),
      !!_vm.plotConfig
        ? _c("canvas", {
            staticClass: "region-track",
            attrs: { id: "track_" + _vm.sectionId, width: "", height: "" },
            on: {
              mouseleave: function ($event) {
                _vm.hidePanel("block_data_" + _vm.sectionId)
                _vm.resetPosMarker()
              },
              mousemove: function ($event) {
                return _vm.checkPosition($event, "hover")
              },
              click: function ($event) {
                return _vm.checkPosition($event, "click")
              },
              resize: _vm.onResize,
            },
          })
        : _vm._e(),
      _c("span", {
        staticClass: "x-pos-marker",
        attrs: { id: _vm.sectionId + "_xPosMarker" },
      }),
      _c("div", { staticClass: "download-images-setting" }, [
        _c(
          "span",
          { staticClass: "btn btn-default options-gear" },
          [_vm._v("Download "), _c("b-icon", { attrs: { icon: "download" } })],
          1
        ),
        _c("ul", { staticClass: "options" }, [
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.downloadImage(
                      "vector_wrapper_" + _vm.sectionId,
                      _vm.sectionId + "_regionTrack",
                      "svg"
                    )
                  },
                },
              },
              [_vm._v("Download SVG")]
            ),
          ]),
          _c("li", [
            _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.downloadImage(
                      "track_" + _vm.sectionId,
                      _vm.sectionId + "_regionTrack",
                      "png"
                    )
                  },
                },
              },
              [_vm._v("Download PNG")]
            ),
          ]),
        ]),
      ]),
      !!_vm.renderData
        ? _c("research-region-track-vector", {
            ref: _vm.sectionId + "_regionTrack",
            attrs: {
              renderData: _vm.renderData,
              renderConfig: _vm.plotConfig,
              colors: _vm.colors.bold,
              margin: _vm.adjPlotMargin,
              region: _vm.viewingRegion,
              sectionId: _vm.sectionId,
              utils: _vm.utils,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }