var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      this.apiParameters != null
        ? _c(
            "div",
            {
              staticClass: "filtering-ui-wrapper search-criteria",
              attrs: { id: "searchCriteria" },
            },
            [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v("Build search criteria"),
              ]),
              _c(
                "div",
                { staticClass: "filtering-ui-content row" },
                [
                  _vm._l(this.apiParameters.parameters, function (parameter) {
                    return _c(
                      "div",
                      { key: parameter.parameter, staticClass: "col" },
                      [
                        _c("div", { staticClass: "label" }, [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(parameter.label) },
                          }),
                        ]),
                        parameter.type == "list" &&
                        parameter.values.length <= 10
                          ? [
                              parameter.type == "list"
                                ? _c(
                                    "select",
                                    {
                                      staticClass:
                                        "custom-select custom-select-search",
                                      attrs: {
                                        id:
                                          "search_param_" + parameter.parameter,
                                      },
                                    },
                                    [
                                      _vm._l(
                                        parameter.values,
                                        function (param) {
                                          return [
                                            _c("option", {
                                              key: param.trim(),
                                              domProps: {
                                                value: param.trim(),
                                                innerHTML: _vm._s(
                                                  _vm.getFileLabel(
                                                    param.trim(),
                                                    parameter.parameter
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        parameter.type == "list" && parameter.values.length > 10
                          ? [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.paramSearch,
                                    expression: "paramSearch",
                                  },
                                ],
                                staticClass: "form-control",
                                domProps: { value: _vm.paramSearch },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.paramSearch = $event.target.value
                                  },
                                },
                              }),
                              parameter.type == "list"
                                ? _c(
                                    "select",
                                    {
                                      class:
                                        "custom-select custom-select-search long-list " +
                                        _vm.getVisibleValues(
                                          parameter.values,
                                          _vm.paramSearch,
                                          parameter.parameter
                                        ),
                                      style:
                                        parameter.values.length > 10 &&
                                        _vm.paramSearch.length <= 2
                                          ? "display:none !important;"
                                          : "",
                                      attrs: {
                                        id:
                                          "search_param_" + parameter.parameter,
                                        size:
                                          parameter.values.length > 10
                                            ? _vm.paramSearch.length > 2
                                              ? 5
                                              : 1
                                            : "auto",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.updateSearchInputByEvent(
                                            $event,
                                            parameter.parameter
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(parameter.values, function (param) {
                                      return _c("option", {
                                        key: param.trim(),
                                        class:
                                          parameter.values.length > 10 &&
                                          _vm.paramSearch.length > 2 &&
                                          !_vm
                                            .getFileLabel(
                                              param.trim(),
                                              parameter.parameter
                                            )
                                            .toLowerCase()
                                            .includes(
                                              _vm.paramSearch.toLowerCase()
                                            )
                                            ? "hidden"
                                            : "",
                                        domProps: {
                                          value: param.trim(),
                                          innerHTML: _vm._s(
                                            _vm.getFileLabel(
                                              param.trim(),
                                              parameter.parameter
                                            )
                                          ),
                                        },
                                      })
                                    }),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _c("div", [
                          parameter.type == "input" &&
                          parameter.values == "kp genes"
                            ? _c(
                                "div",
                                {
                                  style: !!parameter["expand region"]
                                    ? "display: inline-block;"
                                    : "",
                                  attrs: { id: "kp_gene_search_wrapper" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.geneSearch,
                                        expression: "geneSearch",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "search_param_" + parameter.parameter,
                                    },
                                    domProps: { value: _vm.geneSearch },
                                    on: {
                                      keyup: function ($event) {
                                        return _vm.getGenes($event)
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.geneSearch = $event.target.value
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-select custom-select-search",
                                      style:
                                        _vm.kpGenes.length == 0
                                          ? "display:none !important;"
                                          : "",
                                      attrs: {
                                        size:
                                          _vm.kpGenes.length >= 5 ? 5 : "auto",
                                      },
                                    },
                                    [
                                      _vm._l(_vm.kpGenes, function (gene) {
                                        return [
                                          _c("a", {
                                            key: gene,
                                            staticClass:
                                              "custom-select-a-option",
                                            attrs: { href: "javascript:;" },
                                            domProps: {
                                              innerHTML: _vm._s(gene),
                                            },
                                            on: {
                                              click: function ($event) {
                                                parameter[
                                                  "convert to region"
                                                ] &&
                                                parameter[
                                                  "convert to region"
                                                ] == "true"
                                                  ? _vm.getRegion(
                                                      gene,
                                                      parameter.parameter,
                                                      parameter[
                                                        "expand region by"
                                                      ]
                                                    )
                                                  : _vm.setGene(gene)
                                              },
                                            },
                                          }),
                                        ]
                                      }),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e(),
                          !!parameter["expand region"]
                            ? _c("div", { staticClass: "expand-region" }, [
                                _c(
                                  "select",
                                  {
                                    staticClass: "expand-region-select-byor",
                                    attrs: { id: "region_expander" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.expandRegion(
                                          $event,
                                          parameter
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          selected: "selected",
                                          value: "null",
                                        },
                                      },
                                      [_vm._v(" Expand region by: ")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "50000" } },
                                      [_vm._v("± 50 kb")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "100000" } },
                                      [_vm._v("± 100 kb")]
                                    ),
                                    _c(
                                      "option",
                                      { attrs: { value: "150000" } },
                                      [_vm._v("± 150 kb")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "tip-wrapper" },
                                  [
                                    _c("b-icon", {
                                      staticClass: "tip-bigger warning",
                                      attrs: {
                                        icon: "exclamation-circle-fill",
                                      },
                                    }),
                                    _c("div", { staticClass: "tip-content" }, [
                                      _vm._v(
                                        " This feature is in test! Expanding region will refresh the page as a new search with the last search parameters and the expanded region. All filters and previously loaded data will be removed. "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        parameter.type == "input" &&
                        parameter.values != "kp genes"
                          ? _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "search_param_" + parameter.parameter,
                              },
                            })
                          : _vm._e(),
                        parameter.type == "string to array"
                          ? _c("textarea", {
                              staticClass: "form-control",
                              attrs: {
                                rows: "4",
                                cols: "50",
                                id: "search_param_" + parameter.parameter,
                              },
                            })
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  !!this.dataset && _vm.dataComparisonConfig != null
                    ? _c("div", { staticClass: "col" }, [
                        _c("div", {
                          staticClass: "label",
                          domProps: { innerHTML: _vm._s("Compare data") },
                        }),
                        _vm._m(0),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: {
                          click: function ($event) {
                            return _vm.queryAPI()
                          },
                        },
                      },
                      [_vm._v(" Search ")]
                    ),
                  ]),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      !!this.dataFiles && this.dataFiles.length > 1
        ? _c(
            "div",
            {
              staticClass: "filtering-ui-wrapper search-criteria",
              attrs: { id: "searchCriteria" },
            },
            [
              _c("h4", { staticClass: "card-title" }, [_vm._v("Select data")]),
              _c("div", { staticClass: "filtering-ui-content row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "select",
                    {
                      staticClass: "custom-select",
                      attrs: { id: "dataFiles" },
                      on: {
                        change: function ($event) {
                          return _vm.switchData($event)
                        },
                      },
                    },
                    _vm._l(this.dataFiles, function (file) {
                      return _c("option", {
                        key: file.trim(),
                        domProps: {
                          value: file.trim(),
                          innerHTML: _vm._s(_vm.getFileLabel(file.trim())),
                        },
                      })
                    }),
                    0
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      (!!this.dataset && !!this.filters && this.filters.length > 0) ||
      (!!this.dataFiles && this.dataFiles.length > 1)
        ? _c("div", { staticClass: "filtering-ui-wrapper" }, [
            _c("h4", { staticClass: "card-title filter" }, [
              _vm._v("Filter data"),
            ]),
            _c(
              "div",
              { staticClass: "filtering-ui-content row" },
              _vm._l(this.filters, function (filter) {
                return _c(
                  "div",
                  { key: filter.field, class: _vm.getFilterWidthClasses() },
                  [
                    _c("div", {
                      staticClass: "label",
                      domProps: { innerHTML: _vm._s(filter.label) },
                    }),
                    filter.type == "search"
                      ? [
                          !!filter.features &&
                          !!filter.features.includes("autocomplete")
                            ? _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "filter_" + _vm.getColumnId(filter.field),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.buildSuggestions(
                                      $event,
                                      filter.field
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          !filter.features ||
                          (!!filter.features &&
                            !filter.features.includes("autocomplete"))
                            ? _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "filter_" + _vm.getColumnId(filter.field),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.filterData(
                                      $event,
                                      filter.field,
                                      filter.type
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          !!filter.features &&
                          !!filter.features.includes("autocomplete")
                            ? _c(
                                "div",
                                { staticClass: "autocomplete-options" },
                                [
                                  _c(
                                    "ul",
                                    [
                                      _vm._l(
                                        _vm.suggestions.suggestions,
                                        function (suggestion) {
                                          return _vm.suggestions.field ==
                                            filter.field &&
                                            _vm.suggestions.suggestions.length >
                                              0
                                            ? [
                                                _c("li", {
                                                  attrs: { title: suggestion },
                                                  domProps: {
                                                    innerHTML:
                                                      _vm._s(suggestion),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.filterData(
                                                        $event,
                                                        filter.field,
                                                        filter.type,
                                                        "",
                                                        suggestion
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    filter.type == "search exact" ||
                    filter.type == "search greater than" ||
                    filter.type == "search lower than" ||
                    filter.type == "search or" ||
                    filter.type == "search and"
                      ? [
                          _c("input", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "filter_" + _vm.getColumnId(filter.field),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.filterData(
                                  $event,
                                  filter.field,
                                  filter.type
                                )
                              },
                            },
                          }),
                        ]
                      : _vm._e(),
                    filter.type == "search change direction"
                      ? [
                          _c(
                            "select",
                            {
                              staticClass: "egl-filter-direction",
                              attrs: {
                                id:
                                  "filter_" +
                                  _vm.getColumnId(filter.field) +
                                  "_direction",
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { value: "lt", selected: "selected" },
                                },
                                [_vm._v(" <= ")]
                              ),
                              _c("option", { attrs: { value: "gt" } }, [
                                _vm._v(">="),
                              ]),
                            ]
                          ),
                          _c("input", {
                            staticClass: "form-control egl-filter-cd-input",
                            attrs: {
                              type: "text",
                              id: "filter_" + _vm.getColumnId(filter.field),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.filterData(
                                  $event,
                                  filter.field,
                                  filter.type
                                )
                              },
                            },
                          }),
                        ]
                      : filter.type == "dropdown"
                      ? [
                          _c(
                            "select",
                            {
                              staticClass: "custom-select",
                              attrs: {
                                id: "filter_" + _vm.getColumnId(filter.field),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filterData(
                                    $event,
                                    filter.field,
                                    filter.type,
                                    filter.dataType
                                  )
                                },
                              },
                            },
                            [
                              _c("option"),
                              _vm._l(
                                _vm.buildOptions(filter.field),
                                function (value) {
                                  return _c(
                                    "option",
                                    { key: value, domProps: { value: value } },
                                    [_vm._v(" " + _vm._s(value) + " ")]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      this.dataset != null
        ? _c(
            "b-container",
            { staticClass: "search-fields-wrapper" },
            [
              _vm._l(this.filtersIndex, function (value, name, index) {
                return _c(
                  "div",
                  { key: name, class: "search-field f-" + index },
                  _vm._l(
                    value.search.filter((v, i, arr) => arr.indexOf(v) == i),
                    function (v, i) {
                      return value.search.length > 0
                        ? _c("b-badge", {
                            key: v,
                            class: "btn search-bubble " + i,
                            attrs: { pill: "" },
                            domProps: {
                              innerHTML: _vm._s(
                                value["label in bubble"] == true
                                  ? value.field +
                                      ": " +
                                      v +
                                      "&nbsp;<span class='remove'>X</span>"
                                  : v + "&nbsp;<span class='remove'>X</span>"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeFilter(value.field, i)
                              },
                            },
                          })
                        : _vm._e()
                    }
                  ),
                  1
                )
              }),
              this.numberOfSearches() > 1
                ? _c(
                    "b-badge",
                    {
                      staticClass:
                        "badge badge-secondary badge-pill btn search-bubble clear-all-filters-bubble",
                      on: {
                        click: function ($event) {
                          return _vm.removeAllFilters()
                        },
                      },
                    },
                    [_vm._v(" Clear all search ")]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "select",
      { staticClass: "custom-select", attrs: { id: "ifMergeData" } },
      [
        _c("option", { attrs: { value: "newSearch", selected: "" } }, [
          _vm._v("New search"),
        ]),
        _c("option", { attrs: { value: "overlapping" } }, [
          _vm._v("Only overlapping"),
        ]),
        _c("option", { attrs: { value: "all" } }, [_vm._v("All")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }