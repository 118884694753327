var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mbm-plot-content row", attrs: { id: "rp_region_plot" } },
    [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _vm.plotsList.length > 1
            ? _c(
                "div",
                { staticClass: "show-hide-plots" },
                [
                  _c("strong", [_vm._v("Show/hide region plots")]),
                  _vm._l(_vm.plotsList, function (item, itemIndex) {
                    return [
                      item != "Combined"
                        ? _c("span", {
                            key: item,
                            staticClass: "group-bubble",
                            style:
                              "background-color:" +
                              _vm.compareGroupColors[itemIndex] +
                              ";",
                            domProps: { innerHTML: _vm._s(item) },
                            on: {
                              click: function ($event) {
                                _vm.utils.uiUtils.showHideElement(
                                  "assoPlotsWrapper" +
                                    item.replaceAll(" ", "_") +
                                    _vm.sectionId
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      item == "Combined"
                        ? _c(
                            "span",
                            {
                              staticClass: "group-bubble reference",
                              staticStyle: {
                                "background-color": "#ffffff",
                                border: "solid 1px #666666",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.utils.uiUtils.showHideElement(
                                    "assoPlotsWrapperCombined" + _vm.sectionId
                                  )
                                },
                              },
                            },
                            [_vm._v(" Combined ")]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "fixed-info-box hidden",
              attrs: { id: "fixedInfoBox" + _vm.sectionId },
            },
            [
              _c(
                "div",
                {
                  staticClass: "fixed-info-box-close",
                  on: {
                    click: function ($event) {
                      return _vm.showHidePanel("#fixedInfoBox" + _vm.sectionId)
                    },
                  },
                },
                [_c("b-icon", { attrs: { icon: "x-circle-fill" } })],
                1
              ),
              _c(
                "div",
                { staticClass: "fixed-info-box-content" },
                _vm._l(_vm.dotsClicked, function (d, dIndex) {
                  return _c(
                    "div",
                    [
                      _c(
                        "div",
                        [
                          _c("strong", { domProps: { innerHTML: _vm._s(d) } }),
                          !!_vm.renderConfig["star key"] &&
                          _vm.checkStared(d, "fixed panel") == true
                            ? _c("b-icon", {
                                staticStyle: {
                                  color: "#ffcc00",
                                  cursor: "pointer",
                                  "margin-left": "4px",
                                },
                                attrs: { icon: "star-fill" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeStarItem(d)
                                  },
                                },
                              })
                            : _vm._e(),
                          !!_vm.renderConfig["star key"] &&
                          _vm.checkStared(d, "fixed panel") == false
                            ? _c("b-icon", {
                                staticStyle: {
                                  color: "#ffcc00",
                                  cursor: "pointer",
                                  "margin-left": "4px",
                                },
                                attrs: { icon: "star" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addStarItem(d)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("strong", {
                            domProps: {
                              innerHTML: _vm._s("Set this LD reference for: "),
                            },
                          }),
                          _vm._l(_vm.plotsList, function (i, iIndex) {
                            return [
                              i != "Combined" && !!_vm.assoData[i].data[d]
                                ? _c("strong", {
                                    staticClass: "group-bubble",
                                    style:
                                      "background-color:" +
                                      _vm.compareGroupColors[iIndex] +
                                      ";",
                                    domProps: { innerHTML: _vm._s(i) },
                                    on: {
                                      click: function ($event) {
                                        return _vm.resetLdReference(i, d)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              i == "Combined"
                                ? _c("strong", {
                                    staticClass: "group-bubble",
                                    staticStyle: {
                                      "background-color": "#dddddd",
                                    },
                                    domProps: { innerHTML: _vm._s("All") },
                                    on: {
                                      click: function ($event) {
                                        return _vm.resetLdReference("All", d)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                      _vm._l(_vm.plotsList, function (g, gIndex) {
                        return g != "Combined" && !!_vm.assoData[g].data[d]
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.renderConfig["hover content"],
                                function (h, hIndex) {
                                  return _c("div", [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          h +
                                            "(" +
                                            g +
                                            "):" +
                                            _vm.assoData[g].data[d][h]
                                        ),
                                      },
                                    }),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e()
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
            ]
          ),
          !!_vm.renderConfig.legend
            ? _c("div", {
                staticClass: "mbm-plot-legend",
                domProps: { innerHTML: _vm._s(_vm.renderConfig.legend) },
              })
            : _vm._e(),
          !!_vm.renderConfig["ld server"]
            ? _c("div", { staticClass: "region-plot-default-legend" }, [
                _c("span", {
                  staticClass: "plot-legend-dot",
                  staticStyle: { "background-color": "#824099cc" },
                }),
                _c("span", [_vm._v("Reference variant")]),
                _c("span", {
                  staticClass: "plot-legend-dot",
                  staticStyle: { "background-color": "#d0363360" },
                }),
                _c("span", [_vm._v("1 > r2 >= 0.8")]),
                _c("span", {
                  staticClass: "plot-legend-dot",
                  staticStyle: { "background-color": "#ee982d50" },
                }),
                _c("span", [_vm._v("0.8 > r2 >= 0.6")]),
                _c("span", {
                  staticClass: "plot-legend-dot",
                  staticStyle: { "background-color": "#4db05240" },
                }),
                _c("span", [_vm._v("0.6 > r2 >= 0.4")]),
                _c("span", {
                  staticClass: "plot-legend-dot",
                  staticStyle: { "background-color": "#32afd530" },
                }),
                _c("span", [_vm._v("0.4 > r2 >= 0.2")]),
                _c("span", {
                  staticClass: "plot-legend-dot",
                  staticStyle: { "background-color": "#2074b620" },
                }),
                _c("span", [_vm._v("0.2 > r2 > 0")]),
                _c("span", {
                  staticClass: "plot-legend-dot",
                  staticStyle: { "background-color": "#33333320" },
                }),
                _c("span", [_vm._v("No data")]),
              ])
            : _vm._e(),
          !!_vm.renderConfig["ld server"]
            ? _c(
                "div",
                {
                  staticClass: "ld-plots-opener",
                  on: {
                    click: function ($event) {
                      return _vm.utils.uiUtils.showHideElement(
                        "ldPlotsWrapper" + _vm.sectionId
                      )
                    },
                  },
                },
                [_vm._v("View P-Value / LD plot(s)")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "ld-plots-wrapper hidden",
              attrs: { id: "ldPlotsWrapper" + _vm.sectionId },
            },
            [
              _vm._l(_vm.plotsList, function (item, itemIndex) {
                return [
                  _c("h6", {
                    class: "text color-" + itemIndex,
                    domProps: {
                      innerHTML: _vm._s(
                        item != "default"
                          ? item + " <small>*Showing only with LD</small>"
                          : " <small>*Showing only with LD</small>"
                      ),
                    },
                  }),
                  _c("canvas", {
                    staticClass: "ld-plot",
                    attrs: {
                      id:
                        "ld_plot_" + item.replaceAll(" ", "_") + _vm.sectionId,
                      width: "",
                      height: "",
                    },
                    on: {
                      resize: _vm.onResize,
                      click: function ($event) {
                        return _vm.checkPosition($event, item, "LD", "click")
                      },
                      mousemove: function ($event) {
                        return _vm.checkPosition($event, item, "LD", "move")
                      },
                      mouseout: function ($event) {
                        return _vm.onMouseOut(
                          "ldInfoBox" + item + _vm.sectionId
                        )
                      },
                    },
                  }),
                  _c("div", {
                    staticClass: "ld-info-box hidden",
                    attrs: {
                      id:
                        "ldInfoBox" + item.replaceAll(" ", "_") + _vm.sectionId,
                    },
                  }),
                ]
              }),
            ],
            2
          ),
          _vm._l(_vm.plotsList, function (item, itemIndex) {
            return [
              _c(
                "div",
                {
                  staticClass: "asso-plots-wrapper",
                  attrs: {
                    id:
                      "assoPlotsWrapper" +
                      item.replaceAll(" ", "_") +
                      _vm.sectionId,
                  },
                },
                [
                  item != "default"
                    ? _c("h6", {
                        class: "text color-" + itemIndex,
                        domProps: { innerHTML: _vm._s(item) },
                      })
                    : _vm._e(),
                  _c("canvas", {
                    staticClass: "asso-plot",
                    attrs: {
                      id:
                        "asso_plot_" +
                        item.replaceAll(" ", "_") +
                        _vm.sectionId,
                      width: "",
                      height: "",
                    },
                    on: {
                      resize: _vm.onResize,
                      click: function ($event) {
                        return _vm.checkPosition($event, item, "asso", "click")
                      },
                      mousemove: function ($event) {
                        return _vm.checkPosition($event, item, "asso", "move")
                      },
                      mouseleave: function ($event) {
                        return _vm.resetPosMarker()
                      },
                      mouseout: function ($event) {
                        return _vm.onMouseOut(
                          "assoInfoBox" + item + _vm.sectionId
                        )
                      },
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "x-pos-marker",
                      attrs: { id: _vm.sectionId + "_xPosMarker" },
                    },
                    [_vm._v(" ")]
                  ),
                  _c("div", { staticClass: "download-images-setting" }, [
                    _c(
                      "span",
                      { staticClass: "btn btn-default options-gear" },
                      [
                        _vm._v("Download "),
                        _c("b-icon", { attrs: { icon: "download" } }),
                      ],
                      1
                    ),
                    _c("ul", { staticClass: "options" }, [
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function ($event) {
                                _vm.downloadImage(
                                  "vector_wrapper_" + _vm.sectionId,
                                  _vm.sectionId + "_assoPlot",
                                  "svg",
                                  "vector_asso_plot_" + _vm.sectionId,
                                  item.replaceAll(" ", "_"),
                                  _vm.sectionId + "_assoPlot"
                                )
                              },
                            },
                          },
                          [_vm._v("Download SVG")]
                        ),
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function ($event) {
                                _vm.downloadImage(
                                  "asso_plot_" +
                                    item.replaceAll(" ", "_") +
                                    _vm.sectionId,
                                  _vm.sectionId + "_assoPlot",
                                  "png"
                                )
                              },
                            },
                          },
                          [_vm._v("Download PNG")]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "asso-info-box hidden",
                    attrs: {
                      id:
                        "assoInfoBox" +
                        item.replaceAll(" ", "_") +
                        _vm.sectionId,
                    },
                  }),
                ]
              ),
            ]
          }),
          !!_vm.assoData
            ? _c("research-region-plot-vector", {
                ref: _vm.sectionId + "_assoPlot",
                attrs: {
                  assoData: _vm.assoData,
                  ldData: _vm.ldData,
                  recombData: _vm.recombData,
                  renderConfig: _vm.renderConfig,
                  colors: _vm.ldDotColor,
                  margin: _vm.adjPlotMargin,
                  region: _vm.searchingRegion,
                  sectionId: _vm.sectionId,
                  utils: _vm.utils,
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }