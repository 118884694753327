var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticStyle: { display: "flex", "flex-direction": "column", gap: "10px" },
    },
    [
      false
        ? [
            _vm.allMetadata
              ? _c("b-table", {
                  attrs: {
                    items: _vm.allMetadata,
                    fields: _vm.tableColumns,
                    striped: "",
                    hover: "",
                    responsive: "sm",
                    "head-variant": "light",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(datasetId)",
                        fn: function (data) {
                          return [
                            data.item.datasetId !== _vm.datasetId
                              ? _c(
                                  "button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectDataset(
                                          data.item.datasetId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Select")]
                                )
                              : _c("div", [_vm._v("Selected")]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3917555800
                  ),
                })
              : _vm._e(),
          ]
        : _vm._e(),
      !_vm.datasetId
        ? _c("div", { staticStyle: { color: "red", margin: "0 auto" } }, [
            _vm._v(" Please Select a Dataset "),
          ])
        : _vm._e(),
      _vm.datasetId && !_vm.dataLoaded
        ? _c("div", { staticStyle: { margin: "0 auto" } }, [
            _vm._v(" Loading " + _vm._s(_vm.preloadItem) + "... "),
          ])
        : _vm._e(),
      _vm.dataLoaded
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                gap: "20px",
                width: "100%",
              },
            },
            [
              _vm.layout === 0 || _vm.layout === 2
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        gap: "20px",
                        "align-self": "center",
                        background: "#f8f8f8",
                        padding: "20px",
                      },
                    },
                    [
                      _c("research-single-cell-info", {
                        attrs: { data: _vm.metadata },
                      }),
                      _vm.dataReady
                        ? _c(
                            "div",
                            { staticStyle: { display: "flex", gap: "20px" } },
                            [
                              _c("div", { staticClass: "tabs-group" }, [
                                _vm._m(0),
                                _c(
                                  "div",
                                  { staticClass: "tabs-section-wrapper" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tab-section",
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "column",
                                          gap: "20px",
                                          background: "white",
                                          padding: "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              gap: "20px",
                                            },
                                          },
                                          [
                                            _vm.coordinates
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                      width: "min-content",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "justify-content":
                                                            "space-between",
                                                          "align-items":
                                                            "baseline",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "strong",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "16px",
                                                              margin: "0 0 5px",
                                                            },
                                                          },
                                                          [_vm._v("UMAP")]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.metadata.totalCells.toLocaleString()
                                                            ) +
                                                            " cells "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "research-umap-plot-gl",
                                                      {
                                                        attrs: {
                                                          group: _vm.datasetId,
                                                          points:
                                                            _vm.coordinates,
                                                          labels: _vm.fields,
                                                          colors:
                                                            _vm.labelColors,
                                                          cellTypeField:
                                                            _vm.cellTypeField,
                                                          colorByField:
                                                            _vm
                                                              .cellCompositionVars
                                                              .colorByField,
                                                          hoverFields: [],
                                                          highlightLabel:
                                                            _vm
                                                              .cellCompositionVars
                                                              .highlightLabel,
                                                          highlightLabels:
                                                            _vm
                                                              .cellCompositionVars
                                                              .highlightLabels,
                                                          width: 400,
                                                          height: 400,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.fieldsDisplayList
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                      "align-self":
                                                        "flex-start",
                                                      width: "200px",
                                                      height: "400px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "strong",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "16px",
                                                          margin: "0 0 5px",
                                                        },
                                                      },
                                                      [_vm._v("Color By")]
                                                    ),
                                                    _c(
                                                      "research-single-cell-selector",
                                                      {
                                                        attrs: {
                                                          data: _vm.fields[
                                                            "metadata_labels"
                                                          ],
                                                          selectedField:
                                                            _vm
                                                              .cellCompositionVars
                                                              .colorByField,
                                                          layout:
                                                            "dropdown-list",
                                                          colors:
                                                            _vm.labelColors,
                                                        },
                                                        on: {
                                                          "on-update":
                                                            function ($event) {
                                                              return _vm.handleSelectorUpdate(
                                                                $event
                                                              )
                                                            },
                                                          "on-hover": function (
                                                            $event
                                                          ) {
                                                            return _vm.handleSelectorHover(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "flex-direction": "column",
                                              width: "620px",
                                              gap: "10px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "font-size": "16px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-weight": "bold",
                                                    },
                                                  },
                                                  [_vm._v("Cell Proportion")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-style": "italic",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.cellCompositionVars
                                                          .segmentByLabel
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" per "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-style": "italic",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.cellCompositionVars
                                                          .displayByLabel
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  gap: "20px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "flex-grow": "1",
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v("Display"),
                                                    ]),
                                                    _c(
                                                      "research-single-cell-selector",
                                                      {
                                                        attrs: {
                                                          data: _vm.fields[
                                                            "metadata_labels"
                                                          ],
                                                          layout: "dropdown",
                                                          showColor: false,
                                                          selectedField:
                                                            _vm
                                                              .cellCompositionVars
                                                              .displayByLabel,
                                                        },
                                                        on: {
                                                          "on-update":
                                                            function ($event) {
                                                              return _vm.selectSegmentBy(
                                                                $event.selectedField,
                                                                _vm
                                                                  .cellCompositionVars
                                                                  .segmentByLabel
                                                              )
                                                            },
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "flex-grow": "1",
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v("Subset By"),
                                                    ]),
                                                    _c(
                                                      "research-single-cell-selector",
                                                      {
                                                        attrs: {
                                                          data: _vm.fields[
                                                            "metadata_labels"
                                                          ],
                                                          layout: "dropdown",
                                                          showColor: false,
                                                          selectedField: "",
                                                        },
                                                        on: {
                                                          "on-update":
                                                            function ($event) {
                                                              return _vm.selectSegmentBy(
                                                                _vm
                                                                  .cellCompositionVars
                                                                  .displayByLabel,
                                                                $event.selectedField
                                                              )
                                                            },
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "research-single-cell-selector",
                                              {
                                                attrs: {
                                                  data: _vm.fields[
                                                    "metadata_labels"
                                                  ],
                                                  layout: "list",
                                                  listDirection: "horizontal",
                                                  listAlignment: "end",
                                                  colors: _vm.labelColors,
                                                  selectedField:
                                                    _vm.cellCompositionVars
                                                      .segmentByLabel,
                                                },
                                                on: {
                                                  "on-update": function (
                                                    $event
                                                  ) {
                                                    return _vm.handleSelectorUpdate(
                                                      $event
                                                    )
                                                  },
                                                  "on-hover": function (
                                                    $event
                                                  ) {
                                                    return _vm.handleSelectorHover(
                                                      $event
                                                    )
                                                  },
                                                },
                                              }
                                            ),
                                            _c("research-stacked-bar-plot", {
                                              attrs: {
                                                data: _vm.cellCompositionVars
                                                  .segmentByCounts2,
                                                primaryKey:
                                                  _vm.cellCompositionVars
                                                    .displayByLabel,
                                                subsetKey:
                                                  _vm.cellCompositionVars
                                                    .segmentByLabel,
                                                xAxisLabel:
                                                  _vm.cellCompositionVars
                                                    .displayByLabel,
                                                yAxisLabel: "Count",
                                                highlightKey:
                                                  _vm.cellCompositionVars
                                                    .highlightLabel,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "tabs-group" }, [
                                _vm._m(1),
                                _c(
                                  "div",
                                  { staticClass: "tabs-section-wrapper" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tab-section",
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "column",
                                          gap: "20px",
                                          background: "white",
                                          padding: "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              gap: "20px",
                                            },
                                          },
                                          [
                                            _vm.coordinates
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                      width: "min-content",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "justify-content":
                                                            "space-between",
                                                          "align-items":
                                                            "baseline",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "16px",
                                                              margin: "0 0 5px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-weight":
                                                                    "bold",
                                                                },
                                                              },
                                                              [_vm._v("UMAP")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-style":
                                                                    "italic",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .geneExpressionVars
                                                                      .selectedGene
                                                                      ? `${_vm.geneExpressionVars.selectedGene}`
                                                                      : ""
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.metadata.totalCells.toLocaleString()
                                                            ) +
                                                            " cells "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "research-umap-plot-gl",
                                                      {
                                                        attrs: {
                                                          group: _vm.datasetId,
                                                          points:
                                                            _vm.coordinates,
                                                          labels: _vm.fields,
                                                          colors:
                                                            _vm.labelColors,
                                                          expression:
                                                            _vm.expressionData[
                                                              _vm
                                                                .geneExpressionVars
                                                                .selectedGene
                                                            ],
                                                          cellTypeField:
                                                            _vm.cellTypeField,
                                                          hoverFields: [],
                                                          highlightLabel:
                                                            _vm
                                                              .cellCompositionVars
                                                              .highlightLabel,
                                                          highlightLabels:
                                                            _vm
                                                              .cellCompositionVars
                                                              .highlightLabels,
                                                          width: 400,
                                                          height: 400,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "flex-direction": "column",
                                                  "align-self": "flex-start",
                                                  width: "200px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "strong",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "16px",
                                                      margin: "0 0 5px",
                                                    },
                                                  },
                                                  [_vm._v("Gene Search")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                      height: "400px",
                                                      gap: "5px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          gap: "5px",
                                                        },
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.geneToSearch,
                                                              expression:
                                                                "geneToSearch",
                                                            },
                                                          ],
                                                          staticStyle: {
                                                            width: "100%",
                                                            position:
                                                              "relative",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              "Gene name",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.geneToSearch,
                                                          },
                                                          on: {
                                                            keyup: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              return _vm.searchGene(
                                                                _vm.geneToSearch
                                                              )
                                                            },
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.geneToSearch =
                                                                $event.target.value
                                                            },
                                                          },
                                                        }),
                                                        _c(
                                                          "button",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.searchGene(
                                                                  _vm.geneToSearch
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "svg",
                                                              {
                                                                staticStyle: {
                                                                  width: "20px",
                                                                },
                                                                style: `display:${
                                                                  !_vm.geneLoading
                                                                    ? "block"
                                                                    : "none"
                                                                }`,
                                                                attrs: {
                                                                  viewBox:
                                                                    "0 0 24 24",
                                                                  fill: "none",
                                                                  xmlns:
                                                                    "http://www.w3.org/2000/svg",
                                                                  stroke:
                                                                    "#000",
                                                                },
                                                              },
                                                              [
                                                                _c("path", {
                                                                  attrs: {
                                                                    "fill-rule":
                                                                      "evenodd",
                                                                    "clip-rule":
                                                                      "evenodd",
                                                                    d: "M15 10.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-.82 4.74a6 6 0 1 1 1.06-1.06l4.79 4.79-1.06 1.06-4.79-4.79Z",
                                                                    fill: "#080341",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _c("div", {
                                                              staticClass:
                                                                "geneLoader",
                                                              style: `display:${
                                                                _vm.geneLoading
                                                                  ? "block"
                                                                  : "none"
                                                              }`,
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.genesNotFound.length > 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "flex-direction":
                                                                "column",
                                                              gap: "1px",
                                                              flex: "0 0 auto",
                                                              "max-height":
                                                                "100%",
                                                              "overflow-y":
                                                                "auto",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.genesNotFound,
                                                            function (gene) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    gap: "5px",
                                                                    width:
                                                                      "100%",
                                                                    background:
                                                                      "#ff4500",
                                                                    color:
                                                                      "white",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "flex",
                                                                          flex: "1",
                                                                          "align-items":
                                                                            "center",
                                                                          padding:
                                                                            "0 5px",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          gene
                                                                        ) +
                                                                          " not found."
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "28px",
                                                                          height:
                                                                            "28px",
                                                                          display:
                                                                            "flex",
                                                                          "align-items":
                                                                            "center",
                                                                          "justify-content":
                                                                            "center",
                                                                          "font-size":
                                                                            "18px",
                                                                          "line-height":
                                                                            "18px",
                                                                          cursor:
                                                                            "pointer",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.clearGeneNotFound(
                                                                              gene
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "×"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "research-single-cell-selector",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "4px",
                                                          "flex-grow": "1",
                                                          "overflow-x":
                                                            "hidden",
                                                          "overflow-y": "auto",
                                                        },
                                                        attrs: {
                                                          data: _vm.sortedGeneNames,
                                                          layout: "list",
                                                          listSelection:
                                                            "exclusive",
                                                          colors: null,
                                                          selectedField:
                                                            _vm
                                                              .geneExpressionVars
                                                              .selectedGene,
                                                        },
                                                        on: {
                                                          "on-update":
                                                            function ($event) {
                                                              return _vm.geneClick(
                                                                $event
                                                                  .coloredLabels[0]
                                                              )
                                                            },
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm.geneExpressionVars.expressionStats
                                            .length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                    gap: "10px",
                                                    width: "620px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "flex-direction":
                                                          "column",
                                                        gap: "10px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-size": "16px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "bold",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Gene Expression"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-style":
                                                                  "italic",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .geneExpressionVars
                                                                    .selectedGene
                                                                    ? `${_vm.geneExpressionVars.selectedGene}`
                                                                    : ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            gap: "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "flex-grow":
                                                                  "1",
                                                              },
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  "Display"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "research-single-cell-selector",
                                                                {
                                                                  attrs: {
                                                                    data: _vm
                                                                      .fields[
                                                                      "metadata_labels"
                                                                    ],
                                                                    layout:
                                                                      "dropdown",
                                                                    showColor: false,
                                                                    selectedField:
                                                                      _vm
                                                                        .geneExpressionVars
                                                                        .selectedLabel,
                                                                  },
                                                                  on: {
                                                                    "on-update":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.selectExpressionBy(
                                                                          $event.selectedField,
                                                                          _vm
                                                                            .geneExpressionVars
                                                                            .subsetLabel
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "flex-grow":
                                                                  "1",
                                                              },
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  "Subset By"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "research-single-cell-selector",
                                                                {
                                                                  attrs: {
                                                                    data: _vm
                                                                      .fields[
                                                                      "metadata_labels"
                                                                    ],
                                                                    layout:
                                                                      "dropdown",
                                                                    showColor: false,
                                                                    selectedField:
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    "on-update":
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.selectExpressionBy(
                                                                          _vm
                                                                            .geneExpressionVars
                                                                            .selectedLabel,
                                                                          $event.selectedField
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "research-single-cell-selector",
                                                    {
                                                      attrs: {
                                                        data: _vm.fields[
                                                          "metadata_labels"
                                                        ],
                                                        layout: "list",
                                                        listDirection:
                                                          "horizontal",
                                                        listAlignment: "end",
                                                        colors: _vm.labelColors,
                                                        selectedField:
                                                          _vm.geneExpressionVars
                                                            .subsetLabel,
                                                      },
                                                      on: {
                                                        "on-update": function (
                                                          $event
                                                        ) {
                                                          return _vm.handleSelectorUpdate(
                                                            $event
                                                          )
                                                        },
                                                        "on-hover": function (
                                                          $event
                                                        ) {
                                                          return _vm.handleSelectorHover(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    }
                                                  ),
                                                  _c("research-violin-plot", {
                                                    attrs: {
                                                      data: _vm
                                                        .geneExpressionVars
                                                        .expressionStats,
                                                      primaryKey:
                                                        _vm.geneExpressionVars
                                                          .selectedLabel,
                                                      subsetKey:
                                                        _vm.geneExpressionVars
                                                          .subsetLabel,
                                                      highlightKey:
                                                        _vm.cellCompositionVars
                                                          .highlightLabel,
                                                      height: 300,
                                                      xAxisLabel:
                                                        "Log-Normalized Expression",
                                                      yAxisLabel: `${
                                                        _vm.geneExpressionVars
                                                          .selectedLabel
                                                      } ${
                                                        _vm.geneExpressionVars
                                                          .subsetLabel != ""
                                                          ? " x " +
                                                            _vm
                                                              .geneExpressionVars
                                                              .subsetLabel
                                                          : ""
                                                      }`,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticStyle: { display: "flex", gap: "25px" } },
                        [
                          _vm.showMarkerGenes &&
                          (_vm.markerGenes || _vm.expressionStatsAll.length > 0)
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    gap: "20px",
                                    background: "white",
                                    padding: "20px",
                                    width: "100%",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        gap: "20px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "space-between",
                                          },
                                        },
                                        [
                                          _c(
                                            "strong",
                                            {
                                              staticStyle: {
                                                "font-size": "16px",
                                                margin: "0 0 5px",
                                              },
                                            },
                                            [_vm._v("Marker Genes")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "legends",
                                              staticStyle: {
                                                display: "flex",
                                                gap: "5px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "legend",
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "label" },
                                                    [_vm._v("Expression")]
                                                  ),
                                                  _c("div", {
                                                    staticClass: "gradient",
                                                    style: `background: linear-gradient(to right, ${_vm.colorScalePlasmaColorsArray});`,
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "marks",
                                                      staticStyle: {
                                                        display: "flex",
                                                      },
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v("0.0"),
                                                      ]),
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.markerGenesMaxMean
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._m(2),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("research-dot-plot", {
                                        staticStyle: {
                                          display: "flex",
                                          "align-self": "center",
                                        },
                                        attrs: {
                                          data:
                                            _vm.markerGenes ||
                                            _vm.expressionStatsAll,
                                          geneKey:
                                            _vm.expressionStatsAll.length > 0
                                              ? _vm.cellTypeField
                                              : "cellType",
                                          primaryKey: "gene",
                                          orientation: "horizontal",
                                          width: 1300,
                                          fitToSize: true,
                                          cellWidth: 30,
                                          showYLabels: true,
                                          showXLabels: true,
                                          positionXLabelsOnTop: true,
                                          positionYLabelsOnRight: false,
                                          marginBottom: 50,
                                          marginLeft: -20,
                                          marginTop: 10,
                                          marginRight: 10,
                                          highlightKey: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.layout === 1
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        width: "100%",
                        "align-self": "center",
                      },
                    },
                    [
                      _c("research-single-cell-info", {
                        attrs: { data: _vm.metadata },
                      }),
                      _vm.dataReady
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                gap: "40px",
                                flex: "1",
                              },
                            },
                            [
                              _c("research-single-cell-selector", {
                                attrs: {
                                  data: _vm.fields["metadata_labels"],
                                  layout: "dropdown-list",
                                  showSelect: false,
                                  colors: _vm.labelColors,
                                  selectedField:
                                    _vm.cellCompositionVars.colorByField,
                                },
                                on: {
                                  "on-update": function ($event) {
                                    return _vm.handleSelectorUpdate($event)
                                  },
                                  "on-hover": function ($event) {
                                    return _vm.handleSelectorHover($event)
                                  },
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: { display: "flex", gap: "20px" },
                                },
                                [
                                  _vm.coordinates
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "column",
                                            width: "min-content",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                                "align-items": "baseline",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "16px",
                                                    margin: "0 0 5px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                      },
                                                    },
                                                    [_vm._v("UMAP")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-style": "italic",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.geneExpressionVars
                                                            .selectedGene
                                                            ? `${_vm.geneExpressionVars.selectedGene}`
                                                            : ""
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.metadata.totalCells.toLocaleString()
                                                  ) +
                                                  " cells "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                position: "relative",
                                              },
                                            },
                                            [
                                              _c("research-umap-plot-gl", {
                                                attrs: {
                                                  group: _vm.datasetId,
                                                  points: _vm.coordinates,
                                                  labels: _vm.fields,
                                                  colors: _vm.labelColors,
                                                  expression:
                                                    _vm.expressionData[
                                                      _vm.geneExpressionVars
                                                        .selectedGene
                                                    ],
                                                  cellTypeField:
                                                    _vm.cellTypeField,
                                                  hoverFields: [],
                                                  highlightLabel:
                                                    _vm.cellCompositionVars
                                                      .highlightLabel,
                                                  highlightLabels:
                                                    _vm.cellCompositionVars
                                                      .highlightLabels,
                                                  width: 400,
                                                  height: 400,
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "legend",
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                    position: "absolute",
                                                    top: "4px",
                                                    left: "5px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "label" },
                                                    [_vm._v("Expression")]
                                                  ),
                                                  _c("div", {
                                                    staticClass: "gradient",
                                                    style: `background: linear-gradient(to right, ${_vm.colorScalePlasmaColorsArray}); height:5px;`,
                                                  }),
                                                  _vm._m(3),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm.geneExpressionVars.expressionStats.length > 0
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        flex: "1",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "column",
                                            gap: "5px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "16px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [_vm._v("Gene Expression")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-style": "italic",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.geneExpressionVars
                                                        .selectedGene
                                                        ? `${_vm.geneExpressionVars.selectedGene}`
                                                        : ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("research-violin-plot", {
                                        attrs: {
                                          data: _vm.geneExpressionVars
                                            .expressionStats,
                                          primaryKey:
                                            _vm.geneExpressionVars
                                              .selectedLabel,
                                          subsetKey:
                                            _vm.geneExpressionVars.subsetLabel,
                                          highlightKey:
                                            _vm.cellCompositionVars
                                              .highlightLabel,
                                          height: 400,
                                          xAxisLabel:
                                            "Log-Normalized Expression",
                                          yAxisLabel:
                                            _vm.geneExpressionVars
                                              .selectedLabel,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dataReady && _vm.layout === 3
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        width: "100%",
                        background: "#f8f8f8",
                        padding: "20px",
                        gap: "20px",
                      },
                    },
                    [
                      _c("research-single-cell-info", {
                        attrs: { data: _vm.metadata },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            gap: "20px",
                            background: "white",
                            padding: "20px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                                gap: "10px",
                              },
                            },
                            [
                              _vm._m(4),
                              _c(
                                "div",
                                {
                                  staticStyle: { display: "flex", gap: "20px" },
                                },
                                [
                                  _c("research-single-cell-selector", {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      data: _vm.fields["metadata_labels"],
                                      layout: "dropdown-list",
                                      colors: _vm.labelColors,
                                      selectedField:
                                        _vm.cellCompositionVars.colorByField,
                                    },
                                    on: {
                                      "on-update": function ($event) {
                                        return _vm.handleSelectorUpdate($event)
                                      },
                                      "on-hover": function ($event) {
                                        return _vm.handleSelectorHover($event)
                                      },
                                    },
                                  }),
                                  _c("research-umap-plot-gl", {
                                    attrs: {
                                      group: _vm.datasetId,
                                      points: _vm.coordinates,
                                      labels: _vm.fields,
                                      colors: _vm.labelColors,
                                      cellTypeField: _vm.cellTypeField,
                                      hoverFields: [],
                                      colorByField:
                                        _vm.cellCompositionVars.colorByField,
                                      highlightLabel:
                                        _vm.cellCompositionVars.highlightLabel,
                                      highlightLabels:
                                        _vm.cellCompositionVars.highlightLabels,
                                      width: 400,
                                      height: 400,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                                gap: "10px",
                                flex: "1",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "font-size": "16px" } },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Cell Proportion")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-style": "italic" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.cellCompositionVars.segmentByLabel
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" per "),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-style": "italic" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.cellCompositionVars.displayByLabel
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: { display: "flex", gap: "20px" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "flex-grow": "1" } },
                                    [
                                      _c("div", [_vm._v("Display")]),
                                      _c("research-single-cell-selector", {
                                        attrs: {
                                          data: _vm.fields["metadata_labels"],
                                          layout: "dropdown",
                                          selectedField:
                                            _vm.cellCompositionVars
                                              .colorByField,
                                        },
                                        on: {
                                          "on-update": function ($event) {
                                            return _vm.selectSegmentBy(
                                              $event.selectedField,
                                              _vm.cellCompositionVars
                                                .segmentByLabel
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "flex-grow": "1" } },
                                    [
                                      _c("div", [_vm._v("Subset By")]),
                                      _c("research-single-cell-selector", {
                                        attrs: {
                                          data: _vm.fields["metadata_labels"],
                                          layout: "dropdown",
                                          selectedField: "",
                                        },
                                        on: {
                                          "on-update": function ($event) {
                                            return _vm.selectSegmentBy(
                                              _vm.cellCompositionVars
                                                .displayByLabel,
                                              $event.selectedField
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("research-stacked-bar-plot", {
                                attrs: {
                                  data: _vm.cellCompositionVars
                                    .segmentByCounts2,
                                  primaryKey:
                                    _vm.cellCompositionVars.displayByLabel,
                                  subsetKey:
                                    _vm.cellCompositionVars.segmentByLabel,
                                  xAxisLabel:
                                    _vm.cellCompositionVars.displayByLabel,
                                  yAxisLabel: "Count",
                                  highlightKey:
                                    _vm.cellCompositionVars.highlightLabel,
                                  height: 300,
                                },
                              }),
                              _c("research-single-cell-selector", {
                                attrs: {
                                  data: _vm.fields["metadata_labels"],
                                  layout: "list",
                                  listDirection: "horizontal",
                                  listAlignment: "center",
                                  colors: _vm.labelColors,
                                  selectedField:
                                    _vm.cellCompositionVars.segmentByLabel,
                                },
                                on: {
                                  "on-update": function ($event) {
                                    return _vm.handleSelectorUpdate($event)
                                  },
                                  "on-hover": function ($event) {
                                    return _vm.handleSelectorHover($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            gap: "20px",
                            background: "white",
                            padding: "20px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                                gap: "10px",
                                flex: "1",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "font-size": "16px" } },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "bold" } },
                                    [_vm._v("Gene Expression")]
                                  ),
                                  _vm._v(" "),
                                  _c("em", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.geneExpressionVars.selectedGene
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: { display: "flex", gap: "20px" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        height: "400px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            gap: "5px",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.geneToSearch,
                                                expression: "geneToSearch",
                                              },
                                            ],
                                            staticStyle: {
                                              width: "-webkit-fill-available",
                                            },
                                            attrs: {
                                              type: "text",
                                              placeholder: "Gene name",
                                            },
                                            domProps: {
                                              value: _vm.geneToSearch,
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.searchGene(
                                                  _vm.geneToSearch
                                                )
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.geneToSearch =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _c(
                                            "button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.searchGene(
                                                    _vm.geneToSearch
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticStyle: {
                                                    width: "20px",
                                                  },
                                                  attrs: {
                                                    viewBox: "0 0 24 24",
                                                    fill: "none",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    stroke: "#000",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "fill-rule": "evenodd",
                                                      "clip-rule": "evenodd",
                                                      d: "M15 10.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-.82 4.74a6 6 0 1 1 1.06-1.06l4.79 4.79-1.06 1.06-4.79-4.79Z",
                                                      fill: "#080341",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("research-single-cell-selector", {
                                        staticStyle: {
                                          "margin-top": "4px",
                                          "flex-grow": "1",
                                          "overflow-x": "hidden",
                                          "overflow-y": "auto",
                                          width: "200px",
                                        },
                                        attrs: {
                                          data: _vm.geneNames,
                                          layout: "list",
                                          listSelection: "exclusive",
                                          colors: null,
                                          selectedField:
                                            _vm.geneExpressionVars.selectedGene,
                                        },
                                        on: {
                                          "on-update": function ($event) {
                                            return _vm.geneClick(
                                              $event.coloredLabels[0]
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("research-umap-plot-gl", {
                                    attrs: {
                                      group: _vm.datasetId,
                                      points: _vm.coordinates,
                                      labels: _vm.fields,
                                      colors: _vm.labelColors,
                                      expression:
                                        _vm.expressionData[
                                          _vm.geneExpressionVars.selectedGene
                                        ],
                                      cellTypeField: _vm.cellTypeField,
                                      hoverFields: [],
                                      highlightLabel:
                                        _vm.cellCompositionVars.highlightLabel,
                                      highlightLabels:
                                        _vm.cellCompositionVars.highlightLabels,
                                      width: 400,
                                      height: 400,
                                    },
                                  }),
                                  _vm.expressionData[
                                    _vm.geneExpressionVars.selectedGene
                                  ]
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "column",
                                            gap: "10px",
                                            flex: "1",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                gap: "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "flex-grow": "1",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v("Display"),
                                                  ]),
                                                  _c(
                                                    "research-single-cell-selector",
                                                    {
                                                      attrs: {
                                                        data: _vm.fields[
                                                          "metadata_labels"
                                                        ],
                                                        layout: "dropdown",
                                                        showColor: false,
                                                        selectedField:
                                                          _vm.geneExpressionVars
                                                            .selectedLabel,
                                                      },
                                                      on: {
                                                        "on-update": function (
                                                          $event
                                                        ) {
                                                          return _vm.selectExpressionBy(
                                                            $event.selectedField,
                                                            _vm
                                                              .geneExpressionVars
                                                              .subsetLabel
                                                          )
                                                        },
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "flex-grow": "1",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v("Subset By"),
                                                  ]),
                                                  _c(
                                                    "research-single-cell-selector",
                                                    {
                                                      attrs: {
                                                        data: _vm.fields[
                                                          "metadata_labels"
                                                        ],
                                                        layout: "dropdown",
                                                        showColor: false,
                                                        selectedField: "",
                                                      },
                                                      on: {
                                                        "on-update": function (
                                                          $event
                                                        ) {
                                                          return _vm.selectExpressionBy(
                                                            _vm
                                                              .geneExpressionVars
                                                              .selectedLabel,
                                                            $event.selectedField
                                                          )
                                                        },
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c("research-violin-plot", {
                                            attrs: {
                                              data: _vm.geneExpressionVars
                                                .expressionStats,
                                              primaryKey:
                                                _vm.geneExpressionVars
                                                  .selectedLabel,
                                              subsetKey:
                                                _vm.geneExpressionVars
                                                  .subsetLabel,
                                              highlightKey:
                                                _vm.cellCompositionVars
                                                  .highlightLabel,
                                              height: 300,
                                              xAxisLabel:
                                                "Log-Normalized Expression",
                                              yAxisLabel: `${
                                                _vm.geneExpressionVars
                                                  .selectedLabel
                                              } ${
                                                _vm.geneExpressionVars
                                                  .subsetLabel != ""
                                                  ? " x " +
                                                    _vm.geneExpressionVars
                                                      .subsetLabel
                                                  : ""
                                              }`,
                                            },
                                          }),
                                          _c("research-single-cell-selector", {
                                            attrs: {
                                              data: _vm.fields[
                                                "metadata_labels"
                                              ],
                                              layout: "list",
                                              listDirection: "horizontal",
                                              listAlignment: "center",
                                              colors: _vm.labelColors,
                                              selectedField:
                                                _vm.geneExpressionVars
                                                  .subsetLabel,
                                            },
                                            on: {
                                              "on-update": function ($event) {
                                                return _vm.handleSelectorUpdate(
                                                  $event
                                                )
                                              },
                                              "on-hover": function ($event) {
                                                return _vm.handleSelectorHover(
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm.showMarkerGenes &&
                      (_vm.markerGenes || _vm.expressionStatsAll.length > 0)
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                                gap: "20px",
                                background: "white",
                                padding: "20px",
                                width: "100%",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    gap: "20px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          staticStyle: {
                                            "font-size": "16px",
                                            margin: "0 0 5px",
                                          },
                                        },
                                        [_vm._v("Marker Genes")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "legends",
                                          staticStyle: {
                                            display: "flex",
                                            gap: "5px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "legend",
                                              staticStyle: {
                                                display: "flex",
                                                "flex-direction": "column",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "label" },
                                                [_vm._v("Expression")]
                                              ),
                                              _c("div", {
                                                staticClass: "gradient",
                                                style: `background: linear-gradient(to right, ${_vm.colorScalePlasmaColorsArray});`,
                                              }),
                                              _vm._m(5),
                                            ]
                                          ),
                                          _vm._m(6),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("research-dot-plot", {
                                    staticStyle: {
                                      display: "flex",
                                      "align-self": "center",
                                    },
                                    attrs: {
                                      data:
                                        _vm.markerGenes ||
                                        _vm.expressionStatsAll,
                                      geneKey:
                                        _vm.expressionStatsAll.length > 0
                                          ? _vm.cellTypeField
                                          : "cellType",
                                      primaryKey: "gene",
                                      orientation: "horizontal",
                                      width: 620,
                                      fitToSize: false,
                                      cellWidth: 30,
                                      showYLabels: true,
                                      showXLabels: true,
                                      positionXLabelsOnTop: true,
                                      positionYLabelsOnRight: false,
                                      marginBottom: 50,
                                      marginLeft: -20,
                                      marginTop: 10,
                                      marginRight: 10,
                                      highlightKey: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "tabs-wrapper" }, [
      _c("div", { staticClass: "tab" }, [_vm._v(" Cell Composition ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "tabs-wrapper" }, [
      _c("div", { staticClass: "tab" }, [_vm._v(" Gene Expression ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "legend",
        staticStyle: { display: "flex", "flex-direction": "column" },
      },
      [
        _c("div", { staticClass: "label" }, [_vm._v("% Cells Expressing")]),
        _c(
          "div",
          { staticClass: "circles", staticStyle: { display: "flex" } },
          [
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "20%" },
              }),
            ]),
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "40%" },
              }),
            ]),
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "60%" },
              }),
            ]),
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "80%" },
              }),
            ]),
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "100%" },
              }),
            ]),
          ]
        ),
        _c("div", { staticClass: "marks", staticStyle: { display: "flex" } }, [
          _c("div", [_vm._v("0")]),
          _c("div", [_vm._v("100")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "marks", staticStyle: { display: "flex" } },
      [_c("div", [_vm._v("0.0")]), _c("div", [_vm._v("3.0")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticStyle: { "font-size": "16px" } }, [
      _c("span", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("Cell Composition"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "marks", staticStyle: { display: "flex" } },
      [_c("div", [_vm._v("0.0")]), _c("div", [_vm._v("3.0")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "legend",
        staticStyle: { display: "flex", "flex-direction": "column" },
      },
      [
        _c("div", { staticClass: "label" }, [_vm._v("% Cells Expressing")]),
        _c(
          "div",
          { staticClass: "circles", staticStyle: { display: "flex" } },
          [
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "20%" },
              }),
            ]),
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "40%" },
              }),
            ]),
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "60%" },
              }),
            ]),
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "80%" },
              }),
            ]),
            _c("div", { staticClass: "circleBorder" }, [
              _c("div", {
                staticClass: "circle",
                staticStyle: { height: "100%" },
              }),
            ]),
          ]
        ),
        _c("div", { staticClass: "marks", staticStyle: { display: "flex" } }, [
          _c("div", [_vm._v("0")]),
          _c("div", [_vm._v("100")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }